import { Persona, Text } from "@fluentui/react-components";
import { useStyles } from "../../../../../styles/styleClasses";
import { mergeClasses } from "@fluentui/react-components";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../../contexts/LocalizationContext";

function RequesterField(props: { requester: string }) {
  const styles = useStyles();
  const localized = useLocalization() as LanguageStrings;

  return (
    <div className={styles.overflowHidden}>
      <div className={styles.pbXs}>
        <Text truncate className={styles.textNeutralForeground3}>
          {localized.Requester}
        </Text>
      </div>
      <Persona
        className={mergeClasses(styles.persona, styles.dFlex)}
        size="extra-small"
        name={props.requester}
        textAlignment="center"
      />
    </div>
  );
}

export default RequesterField;
