import { useDebugValue } from "react";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";
import { DisplayMode } from "../interfaces/DisplayMode";
import React from "react";
import { OrderState } from "../interfaces/OrderState";
import { SortingSettings } from "../interfaces/SortingSettings";

export function useApprovalContext() {
  const [domainUrl, setDomainUrl] = React.useState("");
  const [displayMode, setDisplayMode] = React.useState(DisplayMode.Table);
  const [orderState, setOrderState] = React.useState(OrderState.Pending);
  const [sortOptions, setSortOptions] = React.useState(
    localStorage.getItem("SortOptions")
  );

  function saveDomainUrl(url: string): void {
    if (url !== "") {
      localStorage.setItem("ESMDomain", url);
      setDomainUrl(url);
    }
  }

  function getDomainUrl(): string {
    let actualDomainUrl = domainUrl;
    if (actualDomainUrl === "") {
      actualDomainUrl = localStorage.getItem("ESMDomain") ?? "";

      if (actualDomainUrl !== "") {
        setDomainUrl(actualDomainUrl);
      }
    }

    return actualDomainUrl;
  }

  function saveDisplayMode(displayMode: DisplayMode): void {
    setDisplayMode(displayMode);
  }

  function saveSortOptions(sortColumn: string, sortDirection: string) {
    localStorage.setItem("SortOptions", sortColumn + ":" + sortDirection);
    setSortOptions(sortColumn + ":" + sortDirection);
  }

  function getSortOptions() {
    const sortingSettings = {} as SortingSettings;

    if (sortOptions === null) {
      sortingSettings.sortColumn = "ID";
      sortingSettings.sortDirection = "descending";
    } else {
      const sortSettings = sortOptions?.split(":");
      sortingSettings.sortColumn = sortSettings[0];
      if (sortSettings[1] === "ascending") {
        sortingSettings.sortDirection = "ascending";
      } else {
        sortingSettings.sortDirection = "descending";
      }
    }

    return sortingSettings;
  }

  const context: ApprovalUserContext = {
    getDomainUrl: getDomainUrl,
    setDomainUrl: saveDomainUrl,
    displayMode: displayMode,
    setDisplayMode: saveDisplayMode,
    orderState: orderState,
    setOrderState: setOrderState,
    saveSortOptions: saveSortOptions,
    getSortOptions: getSortOptions,
    sortOptions: sortOptions,
  };

  useDebugValue(
    `1st: DomainUrl, 2nd:DisplayMode:  ${
      displayMode === DisplayMode.Table ? "Table" : "Cards"
    }`
  );

  return context;
}
