import axios from "axios";
import { DwpConfig } from "../interfaces/DwpConfig";

export async function extractValidDomainUrl(inputDomainUrl: string): Promise<string> {
    const originUrl = new URL(inputDomainUrl).origin;
    const response = await axios.get<DwpConfig>(`${originUrl}/m42Services/api/wmConfig`)
    const config: DwpConfig = response.data;

    const validDomainUrl: string = config.authentication.oauth2.redirect_uri;
    const validUrl = new URL(validDomainUrl).origin 
    return validUrl.toString();
}


