import {
  TableBody,
  TableCell,
  TableCellActions,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Table,
  Text,
  TableCellLayout,
  mergeClasses,
  Persona,
  Badge,
  Tooltip,
} from "@fluentui/react-components";
import { Order } from "../../interfaces/Order";
import { useStyles } from "../../styles/styleClasses";
import { currencySymbols, headers } from "../../services/Constants";
import AdditionalInfo from "../controls/AdditionalInfo";
import { CostStructure } from "../../interfaces/TotalCosts";
import { getOrderStateColor } from "../../helpers/GetOrderStateColor";
import { LoadMoreOrders } from "../controls/LoadMoreOrders";
import OrderCheckbox from "../controls/OrderCheckbox";
import ApproveButton from "../controls/decisionControls/ApproveButton";
import DeclineButton from "../controls/decisionControls/DeclineButton";
import { SortingSettings } from "../../interfaces/SortingSettings";
import { OrderState } from "../../interfaces/OrderState";
import { calculateQty } from "../../helpers/QtyCalculator";
import { translateDictionaryValues } from "../../helpers/LocalizationHelper";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

export function ApprovalTable(props: {
  rowData: Order[];
  refreshOrder(order: Order): void;
  setDialogData(order: Order): void;
  sortSettings: SortingSettings;
  requireApprovalReason: boolean;
}) {
  const styles = useStyles();
  const translatedHeaders = translateDictionaryValues(headers);
  const localized = useLocalization() as LanguageStrings;

  function displayTwoDigitsValue(value: number) {
    const formattedValue = value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return <>{formattedValue}</>;
  }

  function checkPriceValueIsUndefined(
    cost: CostStructure,
    currencySymbolsValue: string
  ) {
    if (cost !== undefined && cost.Value !== undefined) {
      return (
        <Text className={styles.numberDataRightAlignment}>
          {displayTwoDigitsValue(cost.Value)}{" "}
          {currencySymbols[currencySymbolsValue]}
        </Text>
      );
    }
    return (
      <Text className={styles.numberDataRightAlignment}>
        0.00 {currencySymbols[currencySymbolsValue]}
      </Text>
    );
  }

  function displayServiceItemNumber(order: Order) {
    return (
      <>
        <Text
          className={mergeClasses(styles.noWrap)}
          weight="regular"
          size={100}
          align="start"
        >
          {order.Booking[0].ServiceItemNumber}
        </Text>
        {order.IsMultipleBooking === true && (
          <Text
            truncate
            className={mergeClasses(styles.mlXs, styles.noWrap)}
            weight="regular"
            size={100}
            align="start"
          >
            {localized.MultipleService}
          </Text>
        )}
      </>
    );
  }

  const headerSortProps = (columnId: string) =>
    columnId === props.sortSettings.sortColumn && {
      sortDirection: props.sortSettings.sortDirection,
    };

  return (
    <div>
      <Table
        className={styles.table}
        noNativeElements
        aria-label="Requests List"
      >
        <TableHeader>
          <TableRow>
            {translatedHeaders.map((header) => (
              <TableHeaderCell
                {...headerSortProps(header.label)}
                key={header.headerKey}
              >
                <Text
                  className={
                    header.headerKey === "state" ||
                    header.headerKey === "Recipient"
                      ? styles.ml15
                      : header.headerKey === "price" ||
                        header.headerKey === "totalPrice"
                      ? styles.priceRow
                      : header.headerKey === "Quantity"
                      ? styles.quantiyRow
                      : " "
                  }
                >
                  {" "}
                  {header.label}
                </Text>
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody
          className={mergeClasses(styles.borderNeutral1, styles.borderRadiusMd)}
        >
          {props.rowData.map((item, index) => (
            <TableRow
              appearance={
                item.IsSelected ? ("brand" as const) : ("none" as const)
              }
              key={index}
              onClick={() => props.setDialogData(item)}
            >
              <TableCell>
                <OrderCheckbox item={item} />
                <TableCellLayout truncate appearance="primary">
                  {item.ItemNumber}
                </TableCellLayout>
              </TableCell>
              <TableCell>{item.CreatedDate}</TableCell>
              <TableCell>
                <TableCellLayout
                  truncate
                  description={displayServiceItemNumber(item)}
                  appearance="primary"
                >
                  {item.Booking[0].Name}
                </TableCellLayout>
              </TableCell>
              <TableCell align="center">
                {item.AdditionalData !== undefined ? (
                  <TableCellLayout truncate>
                    <AdditionalInfo formData={item.AdditionalData} />
                  </TableCellLayout>
                ) : (
                  <TableCellLayout truncate>-</TableCellLayout>
                )}
              </TableCell>
              <TableCell>
                <Tooltip
                  withArrow
                  positioning="above"
                  content={
                    item.StateId !== OrderState.Pending
                      ? `${localized.Decided} : ${item.DecidedDate}`
                      : localized.NotDecided
                  }
                  relationship={"label"}
                >
                  <Badge
                    size="medium"
                    color={getOrderStateColor(item.StateId)}
                    shape="rounded"
                    appearance="ghost"
                    className={mergeClasses(
                      styles.overflowHidden,
                      styles.pNone
                    )}
                  >
                    <Text
                      truncate
                      className={styles.noWrap}
                      weight="semibold"
                      size={200}
                    >
                      {item.StateId === OrderState.Pending
                        ? localized.Pending
                        : item.StateId === OrderState.Approved
                        ? localized.Approved
                        : item.StateId === OrderState.Declined
                        ? localized.Declined
                        : item.StateId === OrderState.PendingOther
                        ? localized.PendingOther
                        : localized.Provisioned}
                    </Text>
                  </Badge>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Persona
                  size="extra-small"
                  name={item.Recipient}
                  textAlignment="center"
                />
              </TableCell>
              <TableCell>
                <Text className={styles.numberDataRightAlignment}>
                  {calculateQty(item)}
                </Text>
              </TableCell>
              <TableCell>
                {checkPriceValueIsUndefined(
                  item.TotalCosts.MonthlyRecurringCosts,
                  item.CurrencyCode
                )}
              </TableCell>
              <TableCell>
                {checkPriceValueIsUndefined(
                  item.TotalCosts.Total,
                  item.CurrencyCode
                )}
                {item.StateId === OrderState.Pending && (
                  <>
                    <TableCellActions>
                      <ApproveButton
                        isMobile={false}
                        disabled={false}
                        orderId={item.ObjectId}
                        comments={"Approved from approval app."}
                        popOrder={() => {
                          props.refreshOrder(item);
                        }}
                        overrideOnClick={
                          props.requireApprovalReason
                            ? () => props.setDialogData(item)
                            : undefined
                        }
                      />
                      <DeclineButton
                        isMobile={false}
                        disabled={false}
                        orderId={item.ObjectId}
                        comments={"Declined from approval app."}
                        popOrder={() => {
                          props.refreshOrder(item);
                        }}
                        overrideOnClick={() => props.setDialogData(item)}
                      />
                    </TableCellActions>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
          <LoadMoreOrders />
        </TableBody>
      </Table>
    </div>
  );
}
