import { LanguageStrings, useLocalization } from "../contexts/LocalizationContext";
import { OrderState } from "../interfaces/OrderState";
import { orderStates } from "../services/Constants";

export function mapStateFromEnumToString(state: OrderState) {
  const localized = useLocalization() as LanguageStrings;

  switch (state) {
    case OrderState.All:
      return localized.All;
    case OrderState.Provisioned:
      return localized.Provisioned;
    case OrderState.Declined:
      return localized.Declined;
    case OrderState.Pending:
    default:
      return localized.Pending;
  }
}

export function mapStateFromStringToEnum(state: string) {
  switch (state) {
    case orderStates.All:
      return OrderState.All;
    case orderStates.Provisioned:
      return OrderState.Provisioned;
    case orderStates.Declined:
      return OrderState.Declined;
    case orderStates.Pending:
    default:
      return OrderState.Pending;
  }
}
