import { Button, mergeClasses, Text } from "@fluentui/react-components";
import { useStyles } from "../../styles/styleClasses";
import { useContext } from "react";
import { OrdersContext } from "../../contexts/OrdersContext";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

export function LoadMoreOrders() {
  const styles = useStyles();
  const localized = useLocalization() as LanguageStrings;

  const { retrieveOrderData, moreOrdersAvailable, orders } =
    useContext(OrdersContext);

  if (moreOrdersAvailable === true) {
    return (
      <div className={mergeClasses(styles.column, styles.mtXs)}>
        <Button
          appearance="transparent"
          onClick={() => retrieveOrderData(false)}
        >
          {localized.ViewMore}
        </Button>
      </div>
    );
  } else if (
    orders?.length >= 10 &&
    (orders?.length % 10 !== 0 || moreOrdersAvailable === false)
  ) {
    return (
      <div
        className={mergeClasses(
          styles.column,
          styles.mtXs,
          styles.cursorPointer
        )}
      >
        <Text> {localized.NoMoreItems}</Text>
      </div>
    );
  } else {
    return <></>;
  }
}
