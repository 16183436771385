import { Button, mergeClasses, Tooltip } from "@fluentui/react-components";
import { Dismiss16Filled } from "@fluentui/react-icons";
import { useContext } from "react";
import { useStyles } from "../../../styles/styleClasses";
import { ApprovalUserContext } from "../../../interfaces/ApprovalUserContext";
import { UserContext } from "../../../contexts/UserContext";
import ApprovalService from "../../../services/ApprovalService";
import { ErrorHandlingService } from "../../../services/ErrorHandlingService";
import { DecideButtonProps } from "../../../interfaces/DecideButtonProps";
import {
  LanguageStrings,
  useLocalization,
} from "../../../contexts/LocalizationContext";

export default function DeclineButton({
  disabled,
  orderId,
  isMobile,
  comments,
  popOrder,
  overrideOnClick,
}: DecideButtonProps) {
  const styles = useStyles();
  const userContext = useContext<ApprovalUserContext>(UserContext);
  const localized = useLocalization() as LanguageStrings;

  const onClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    if (overrideOnClick) {
      overrideOnClick();
    } else {
      handleClickDefault();
    }
    e.stopPropagation();
  };

  function handleClickDefault() {
    ApprovalService.declineOrder(userContext, orderId, comments)
      .then(() => {
        popOrder();
      })
      .catch((error) => {
        ErrorHandlingService.handleApiError(error);
      });
  }

  return (
    <Tooltip withArrow content={localized.DeclineRequest} relationship="label">
      <Button
        disabled={disabled}
        onClick={onClickEvent}
        className={
          !disabled
            ? mergeClasses(
                styles.textDanger,
                styles.justifyCenter,
                styles.w100,
                styles.declineButton
              )
            : styles.w100
        }
        icon={<Dismiss16Filled />}
      >
        {isMobile === true && localized.Decline}
      </Button>
    </Tooltip>
  );
}
