import { ReactNode } from "react";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";
import { UserContext } from "./UserContext";
import { useApprovalContext } from "../hooks/useApprovalContext";

type UCPParams = {
    children: ReactNode;
    initialValues?: ApprovalUserContext;
};
  
export const UserContextProvider = ({ children, initialValues }: UCPParams) => {
  let context: ApprovalUserContext;

  if (initialValues === undefined) {
    context = useApprovalContext();
  } else {
    context = initialValues;
  }

  return (
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  );
};