import {
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogTrigger,
  Button,
  mergeClasses,
  Divider,
} from "@fluentui/react-components";
import { Dismiss16Filled } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { useStyles } from "../../../styles/styleClasses";
import DecideMultipleBookings from "../../controls/DecideMultipleBookings";
import { Order } from "../../../interfaces/Order";
import OrderModalContentTitle from "./childComponents/OrderModalContentTitle";
import OrderModalContentBody from "./childComponents/OrderModalContentBody";
import OrderModalComments from "./childComponents/OrderModalComments";
import ApproveButton from "../../controls/decisionControls/ApproveButton";
import DeclineButton from "../../controls/decisionControls/DeclineButton";
import { OrderState } from "../../../interfaces/OrderState";
import ExternalLinkButton from "../../controls/ExternalLinkButton";
import { OrdersContext } from "../../../contexts/OrdersContext";
import { OrderModalHeaderField } from "./childComponents/OrderModalHeaderField";
import {
  LanguageStrings,
  useLocalization,
} from "../../../contexts/LocalizationContext";

export default function OrderModel(props: {
  order: Order;
  refreshOrder(): void;
  closeDialog(): void;
  requireApprovalReason: boolean;
}) {
  const styles = useStyles();
  const [inputComment, setInputComment] = useState("");
  const orderManagementContext = useContext(OrdersContext);
  const localized = useLocalization() as LanguageStrings;

  useEffect(() => {
    {
      props.order.IsMultipleBooking === true &&
        orderManagementContext.addSelectedBookings(props.order);
    }
  }, []);

  function popOrder() {
    props.refreshOrder();
    props.closeDialog();
  }
  return (
    <div>
      <DialogSurface
        className={mergeClasses(styles.dialog, styles.dFlex, styles.flexColumn)}
      >
        <DialogTitle>
          <DialogTrigger disableButtonEnhancement>
            <Button
              className={mergeClasses(
                styles.closeButtonTrigger,
                styles.btnAvailableInfo
              )}
              icon={<Dismiss16Filled />}
              appearance="transparent"
              iconPosition="after"
            />
          </DialogTrigger>
          <OrderModalHeaderField title={localized.ReviewRequestDetails} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <OrderModalContentTitle order={props.order} />
          <OrderModalContentBody order={props.order} />
          {props.order.StateId === OrderState.Pending && (
            <OrderModalComments
              isMultipleBooking={props.order?.IsMultipleBooking}
              inputComment={inputComment}
              setInputComment={setInputComment}
              required={props.requireApprovalReason}
            />
          )}
        </DialogContent>
        <Divider />
        {props.order.StateId === OrderState.Pending ? (
          <>
            {props.order.IsMultipleBooking === true ? (
              <DialogActions
                className={mergeClasses(styles.pLg, styles.justifyBetween)}
              >
                <DecideMultipleBookings
                  disabled={
                    props.requireApprovalReason && inputComment.length === 0
                  }
                  popOrder={popOrder}
                  objectId={props.order.ObjectId}
                  comments={inputComment}
                />
                <ExternalLinkButton objectId={props.order.ObjectId} />
              </DialogActions>
            ) : (
              <DialogActions
                className={mergeClasses(styles.pLg, styles.justifyBetween)}
              >
                <div className={mergeClasses(styles.dFlex, styles.gapSm)}>
                  <DialogTrigger disableButtonEnhancement>
                    <ApproveButton
                      isMobile={true}
                      disabled={
                        props.requireApprovalReason && inputComment.length === 0
                      }
                      orderId={props.order?.ObjectId}
                      comments={inputComment}
                      popOrder={popOrder}
                    />
                  </DialogTrigger>
                  <DialogTrigger disableButtonEnhancement>
                    <DeclineButton
                      isMobile={true}
                      disabled={inputComment.length === 0}
                      orderId={props.order?.ObjectId}
                      comments={inputComment}
                      popOrder={popOrder}
                    />
                  </DialogTrigger>
                </div>
                <ExternalLinkButton objectId={props.order.ObjectId} />
              </DialogActions>
            )}
          </>
        ) : (
          <DialogActions
            className={mergeClasses(styles.pLg, styles.justifyBetween)}
          >
            <ExternalLinkButton objectId={props.order.ObjectId} />
          </DialogActions>
        )}
      </DialogSurface>
    </div>
  );
}
