import { Checkbox } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { OrdersContext } from "../../contexts/OrdersContext";
import { updateSelectedAllData } from "../../helpers/UpdateSelectedData";
import { clearSelectedAllData } from "../../helpers/ClearSelectedAllData";
import { OrderState } from "../../interfaces/OrderState";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function SelectAllButton() {
  const orderManagementContext = useContext(OrdersContext);
  const localized = useLocalization() as LanguageStrings;

  const [isSelected, setIsSelected] = useState(
    orderManagementContext.isSelectAll
  );

  useEffect(() => {
    setIsSelected(orderManagementContext.isSelectAll);
  }, [orderManagementContext.isSelectAll]);

  function onSelectAllClicked() {
    const valueToggled = !orderManagementContext.isSelectAll;

    orderManagementContext.updateSelectAll(valueToggled);

    if (valueToggled === true) {
      updateSelectedAllData(orderManagementContext);
    } else {
      clearSelectedAllData(orderManagementContext);
    }
  }

  return (
    <Checkbox
      disabled={
        orderManagementContext.orders.find(
          (item) =>
            item.StateId === OrderState.Pending && !item.IsMultipleBooking
        ) === undefined
      }
      checked={isSelected}
      onChange={onSelectAllClicked}
      label={localized.SelectAll}
    />
  );
}

export default SelectAllButton;
