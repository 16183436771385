import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

import {
  bodyBg,
  spacingLg,
  spacingSm,
  breakpointXs,
  breakpointSm,
  spacingXl,
  spacingMd,
  toolbarHeight,
  spacingXxl,
  spacingXs,
  breakpointXl,
  breakpointMd,
  breakpointXsm,
  toolbarHeightMobile,
  breakpointXxl,
} from "./variables";

const useStyles = makeStyles({
  // Style Helper Utilities

  dGrid: {
    display: "grid",
  },

  justifyCenter: {
    justifyContent: "center",
  },

  justifyEnd: {
    justifyContent: "flex-end",
  },

  justifyBetween: {
    justifyContent: "space-between",
  },

  overflowHidden: {
    ...shorthands.overflow("hidden"),
  },

  textOverflow: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
 flexNone:{
  flexDirection:"row-reverse" },
  dFlex: {
    display: "flex",
  },
  noTouch:{
    touchAction:"none",
  },
  
  alignTextRight:{
    textAlign:"right",
  },

  alignCenter: {
    alignItems: "center",
  },

  flexGrow: {
    flexGrow: 1,
  },

  flexColumn: {
    flexDirection: "column",
  },

  w100: {
    width: "100%",
  },
  w75px: {
    width: "75px",
  },

  pXl: {
    ...shorthands.padding(0),

    [`@media screen and (min-width: ${breakpointMd}px)`]: {
      ...shorthands.padding(spacingXxl),
    },

    [`@media screen and (min-width: ${breakpointSm}px)`]: {
      ...shorthands.padding(spacingLg),
    },
  },

  gapXl: {
    ...shorthands.gap(spacingLg),
    [`@media screen and (min-width: ${breakpointMd}px)`]: {
      ...shorthands.gap(spacingXxl),
    },
  },
  gapXs: {
    ...shorthands.gap(spacingXs),
    [`@media screen and (min-width: ${breakpointMd}px)`]: {
      ...shorthands.gap(spacingXs),
    },
  },
  pXxl: {
    ...shorthands.padding(0, spacingSm),
    [`@media screen and (min-width: ${breakpointMd}px)`]: {
      ...shorthands.padding(0, spacingXxl),
    },
  },

  pNone: {
    ...shorthands.padding(0),
  },

  mNone: {
    ...shorthands.margin(0),
  },

  pSm: {
    ...shorthands.padding(spacingSm),
  },

  ptSm: {
    paddingTop: spacingSm,
  },

  ptXs: {
    paddingTop: spacingXs,
  },

  ptMd: {
    [`@media screen and (min-width: ${breakpointSm}px)`]: {
      paddingTop: spacingMd,
    },
  },

  ptLg: {
    paddingTop: spacingLg,
  },

  ptNone: {
    paddingTop: 0,
  },

  plNone: {
    paddingLeft: 0,
  },

  pMd: {
    ...shorthands.padding(spacingMd),
  },

  pLg: {
    ...shorthands.padding(spacingLg),
  },

  plSm: {
    paddingLeft: spacingSm,
  },

  prXs: {
    paddingRight: spacingXs,
  },

  prSm: {
    paddingRight: spacingSm,
  },

  plXs: {
    paddingLeft: spacingXs,
  },

  plMd: {
    paddingLeft: spacingMd,
  },

  pbXs: {
    paddingBottom: spacingXs,
  },

  pbSm: {
    paddingBottom: spacingSm,
  },

  mxAuto: {
    ...shorthands.margin("0", "auto"),
  },
  mrLg: {
    marginRight: spacingXxl,
  },
  mlSm: {
    marginLeft: spacingSm,
  },
  mlLg: {
    marginLeft: spacingLg,
  },
  mlXs: {
    marginLeft: spacingXs,
  },
  mbXs: {
    marginBottom: spacingXs,
  },

  mblg: {
    marginBottom: spacingLg,
  },
  mtLg: {
    marginTop: spacingLg,
  },
  mt0: {
    top: "0 !important",
  },
  mrSm: {
    marginRight: spacingSm,
  },
  mtSm: {
    marginTop: spacingSm,
  },
  mtXs: {
    marginTop: spacingXs,
  },
  ml15: {
    marginLeft: "-15%",
  },
  gapNone: {
    ...shorthands.gap(0),
  },

  gapSm: {
    ...shorthands.gap(spacingSm),
  },

  textDanger: {
    color: tokens.colorPaletteRedForeground1,
  },

  textNeutralForeground1: {
    color: tokens.colorNeutralForeground1,
  },
  textNeutralForeground3: {
    color: tokens.colorNeutralForeground3,
  },

  textSemiBold: {
    fontWeight: tokens.fontWeightSemibold,
  },
  numberDataRightAlignment: {
    textAlign: "right",
    width: "50%",
  },
  textSizeLg: {
    fontSize: tokens.fontSizeBase400,
  },

  borderNeutral1: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
  },

  bgNeutral2: {
    backgroundColor: tokens.colorNeutralBackground2,
  },

  bgAlpha1: {
    backgroundColor: tokens.colorNeutralBackground4Pressed,
  },

  borderRadiusMd: {
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },

  borderRadiusLg: {
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
  },

  noWrap: {
    whiteSpace: "nowrap",
  },

  hideXs: {
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        display: "none",
      },
  },

  showXs: {
    display: "none",
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        display: "block",
      },
  },

  // COMPONENTS STYLING

  // -- Layout Blueprint

  cursorPointer: {
    cursor: "pointer", 
  },

  page: {
    width:"100%",
    backgroundColor: bodyBg,
    minHeight: "50vh",
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointMd}px)`]:
    {
      minHeight: "100vh",
    },
  },

  container: {
    width: "100%",
    boxSizing: "border-box",
  },

  closeButtonTrigger: {
    position: "absolute",
    top: "36px",
    right: spacingSm,
  },

  gridLayout: {
    ...shorthands.gap(spacingLg),

    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        gridTemplateColumns: "1fr",
        ...shorthands.gap(0),
      },

    [`@media screen and (min-width: ${breakpointMd}px) and (max-width: ${breakpointXl}px)`]:
      {
        gridTemplateColumns: "1fr 1fr",
        ...shorthands.gap(spacingXxl),
      },

    [`@media screen and (min-width: ${breakpointXl}px) and (max-width: ${breakpointXxl}px)`]:
      {
        gridTemplateColumns: "1fr 1fr 1fr",
        ...shorthands.gap(spacingXxl),
      },

    [`@media screen and (min-width: ${breakpointXxl}px)`]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      ...shorthands.gap(spacingXxl),
    },
  },

  gridLayoutInner: {
    ...shorthands.gap(spacingLg),
    gridTemplateColumns: "1fr",

    [`@media screen and (min-width: ${breakpointSm}px)`]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },

  gridLayoutDialog: {
    ...shorthands.gap(spacingLg),
    gridTemplateColumns: "1fr",

    [`@media screen and (min-width: ${breakpointSm}px)`]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },

  hInherit: {
    height: "inherit",
  },

  responsiveTitle: {
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        flexDirection: "column",
        display: "flex",
        alignItems: "flex-start",
      },
  },

  responsiveHeading: {
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        paddingTop: spacingLg,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",

        "& .fui-Badge": {
          marginLeft: 0,
        },

        "& .timelineBtn": {
          paddingTop: 0,
        },
      },
  },

  persona: {
    "& .fui-Persona__primaryText": {
      ...shorthands.overflow("hidden"),
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },

  additionalInfo: {
    [`@media screen and (min-width: ${breakpointSm}px)`]: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
    },
  },

  formValidation: {
    "& .validation-error": {
      display: "none",
    },

    "&.active": {
      "& .validation-error": {
        display: "block",
      },

      "& .fui-Textarea, .fui-Textarea:focus, .fui-Textarea:active, .fui-Textarea textarea:focus":
        {
          ...shorthands.borderColor(tokens.colorPaletteRedBackground3),
        },

      "& .fui-Textarea:after": {
        ...shorthands.borderColor(tokens.colorPaletteRedBackground3),
      },
    },
  },

  // -- Layout Components

  toolbar: {
    height: toolbarHeight,
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 5,
    backgroundColor: bodyBg
  },

  toolbarContent: {
    height: toolbarHeight,
  },

  toolbarOffset: {
    ...shorthands.padding(toolbarHeight, 0, 0),

    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        ...shorthands.padding(toolbarHeightMobile, 0, 0),
      },
  },

  buttonTag: {
    ...shorthands.padding(spacingSm, "10px", spacingSm, spacingLg),

    "&:hover, &:active:hover, &.active": {
      backgroundColor: tokens.colorBrandBackground2,
      ...shorthands.borderColor(tokens.colorBrandBackground),
      color: tokens.colorCompoundBrandForeground1Pressed,
    },
  },

  decideMenu: {
    ...shorthands.padding(spacingXs, spacingXs, spacingXs, spacingXs),
    ...shorthands.gap(spacingSm),
    display: "grid",
  },

  orderIdentification: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
    ...shorthands.overflow("hidden"),
  },

  // -- Listing Table

  table: {

    "& .fui-TableBody": {
      top: "35px",
      position: "relative",
      cursor: "pointer",
      backgroundColor: tokens.colorNeutralForegroundInverted,

      "& .fui-TableRow": {
        ...shorthands.overflow("hidden"),

        "& .fui-TableCell": {
          minHeight: "64px",
        },

        "&:first-child, &:last-child": {
          ...shorthands.borderRadius(
            tokens.borderRadiusMedium,
            tokens.borderRadiusMedium,
            0,
            0
          ),
        },
        "&:first-child:last-child": {
          ...shorthands.borderRadius("inherit"),
        },
        "&:last-child": {
          ...shorthands.borderWidth(0),
          ...shorthands.borderRadius(
            0,
            0,
            tokens.borderRadiusMedium,
            tokens.borderRadiusMedium
          ),
        },

        "& .fui-TableCellActions": {
          ...shorthands.padding(spacingLg, spacingSm, spacingLg, spacingXl),
          minWidth: "280px",
          backgroundColor: "transparent",
          backgroundImage: [
            `linear-gradient(to right,  rgba(0,0,0,0) 0%, ${tokens.colorNeutralForegroundInverted} 50%)`,
          ],
          justifyContent: "flex-end",
          display: "flex",
          ...shorthands.gap(spacingSm),
        },

        "& .fui-TableHeaderCell__button": {
          minHeight: "42px",
        },
        "& .fui-TableCellLayout__description": {
          textOverflow: "ellipsis",
          overflowX: "hidden",
          whiteSpace: "nowrap",
        },
      },
    },

    "& .fui-TableHeader": {
      "& .fui-TableRow": {
        height: "60px",
        marginTop: "-32px",
        position: "fixed",
        width: "100%",
        zIndex: 2,

        backgroundColor: bodyBg,
        ...shorthands.borderWidth(0),
      },
    },
  },

  // -- Listing Card

  card: {
    ...shorthands.transition([["all", ".2s"]]),
    boxShadow: tokens.shadow4,

    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        ...shorthands.borderWidth(0),
        ...shorthands.borderBottom(
          "3px",
          "solid",
          tokens.colorNeutralForeground2BrandHover
        ),
        ...shorthands.borderRadius(0),

        "&:first-child": {
          ...shorthands.borderTop(
            "3px",
            "solid",
            tokens.colorNeutralForeground2BrandHover
          ),
        },
      },

    [`@media screen and (min-width: ${breakpointSm}px)`]: {
      "&:hover": {
        boxShadow: tokens.shadow16,
        ...shorthands.borderColor(tokens.colorNeutralForeground2BrandHover),
      },
    },
  },

  cardSelected: {
    [`@media screen and (min-width: ${breakpointSm}px)`]: {
      ...shorthands.borderColor(tokens.colorNeutralForeground2BrandHover),
      boxShadow: "0px 0px 0px 3px " + tokens.colorNeutralForeground2BrandHover,

      "&:hover": {
        boxShadow:
          "0px 0px 0px 3px " + tokens.colorNeutralForeground2BrandHover,
      },
    },
  },

  badgeLinkIcon: {
    ...shorthands.padding(0),
    ...shorthands.border(0),

    "& .fui-PresenceBadge": {
      marginRight: spacingSm,
    },
  },
  declineButton:{
    "& .fui-Button__icon": {
      marginRight: "0 !important"
    },
  },
  btnAvailableInfo: {
    ...shorthands.padding(0),
    ...shorthands.border(0),
    fontWeight: tokens.fontWeightRegular,
    color: tokens.colorBrandBackground,

    "& .fui-PresenceBadge": {
      marginRight: spacingSm,
    },

    "& .fui-PresenceBadge__icon": {
      height: "20px",
    },

    "& svg": {
      height: "20px",
      width: "20px",
    },

    "& .fui-Button__icon": {
      width: "16px",
    },
  },

  pricingOuter: {
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        ...shorthands.margin("-8px", 0),
      },

    [`@media screen and (max-width: ${breakpointXsm}px)`]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  pricingInner: {
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        flexDirection: "column",
        alignItems: "flex-start",
        ...shorthands.gap(spacingXs),
      },
  },

  priceBlock: {
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        display: "flex",
        ...shorthands.padding(spacingXs, 0),
      },
  },

  textResponsive: {
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointSm}px)`]:
      {
        width: "110px",
        textAlign: "left",
      },
  },

  priceBlockTotal: {
    [`@media screen and (max-width: ${breakpointXsm}px)`]: {
      paddingTop: spacingSm,
      display: "flex",
      "& .fui-Text:first-child": {
        textAlign: "left",
        width: "110px",
      },
    },
  },

  image: {
    width: "44px",
    height: "44px",

    "& img": {
      width: "auto",
      height: "100%",
    },
  },

  imageLg: {
    width: "auto",
    height: "54px",
  },

  footerActions: {
    display: "none",
    ...shorthands.gap(tokens.spacingHorizontalS),
  },

  // Dialog

  dialog: {
    ...shorthands.padding(0),
    maxWidth: `${breakpointMd}px`,
    maxHeight: "94vh",
    height: "fit-content",

    [`@media screen and (max-width: ${breakpointMd}px)`]: {
      ...shorthands.borderRadius(0),
      height: "100%",
      maxHeight: "100vh",
    },
  },

  decideSelectedModalButtonParent:{
    marginRight:spacingXl,
    marginBottom:spacingXl,
    textAlign: "right",
    width:"100%"
  },


  accordion: {
    ...shorthands.borderRadius(
      tokens.borderRadiusLarge,
      tokens.borderRadiusLarge,
      0,
      0
    ),
    borderBottomWidth: 0,
  },

  accordionHeader: {
    ...shorthands.borderWidth(0, 0, "1px", 0),

    "& .fui-AccordionHeader__button": {
      ...shorthands.borderRadius("inherit"),
      fontWeight: tokens.fontWeightSemibold,

      "&:hover": {
        backgroundColor: tokens.colorNeutralBackground3,
      },
    },
  },

  pricing: {
    ...shorthands.borderRadius(
      0,
      0,
      tokens.borderRadiusLarge,
      tokens.borderRadiusLarge
    ),
  },

  timeline: {
    position: "relative",

    "& .fui-Avatar": {
      backgroundColor: tokens.colorNeutralForegroundInverted,

      "&:before": {
        ...shorthands.margin(0),
      },
    },

    "& .fui-PresenceBadge": {
      bottom: "-6px",
      right: "-6px",
    },
  },

  timelineIndicators: {
    position: "absolute",
    right: 0,
    width: "100%",
    top: "52px",
    alignItems: "stretch",
    zIndex: 5,
  },

  timelineIndicator: {
    height: "1px",
    ...shorthands.margin(0, "2px"),
    ...shorthands.flex(1),
    ...shorthands.borderBottom("2px", "dashed", "transparent"),
  },

  approved: {
    ...shorthands.borderColor(tokens.colorPaletteGreenBackground3),
  },

  pending: {
    ...shorthands.borderColor(tokens.colorPaletteMarigoldBackground3),
  },

  declined: {
    ...shorthands.borderColor(tokens.colorPaletteRedBackground3),
  },

  timelineBox: {
    ...shorthands.padding(spacingMd),
    minWidth: "100px",
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.border("1px", "solid", "transparent"),
    zIndex: 6,
    position: "relative",

    "&.current": {
      ...shorthands.borderColor(tokens.colorNeutralForeground2BrandHover),
      ...shorthands.margin("-12px", 0),
      ...shorthands.padding(spacingXl, spacingMd),
      backgroundColor: tokens.colorNeutralForegroundInverted,
      boxShadow: `${tokens.shadow4}, ${tokens.shadow8}, 0px 0px 0px 3px ${tokens.colorNeutralForeground2BrandHover}`,
    },
  },

  multipleBookingApprovedBackground:{
    backgroundColor: 'rgba(231,242,218,255) ',
    "&:hover, &:active:hover, &.active": {
        backgroundColor:`rgba(231,242,218,255) !important`,
    },
    "& .fui-Checkbox__indicator": {
      backgroundColor:`#92C353 !important`,
    },
  },

  multipleBookingDeclinedBackground:{
    backgroundColor:'rgba(252,244,246,255)',
    "&:hover, &:active:hover, &.active": {
      backgroundColor:`rgba(252,244,246,255) !important`,
    },
  },
  multipleBookingApprovedBackgroundDarkTheme:{
    backgroundColor: 'rgba(13,46,13,255)',
    "&:hover, &:active:hover, &.active": {
        backgroundColor:`rgba(13,46,13,255) !important`,
    },
    "& .fui-Checkbox__indicator": {
      backgroundColor:`#92C353 !important`,
    },
  },

  multipleBookingDeclinedBackgroundDarkTheme:{
    backgroundColor:'rgba(62,31,37,255)',
    "&:hover, &:active:hover, &.active": {
      backgroundColor:`rgba(62,31,37,255) !important`,
    },
  },

  bookingTreeChild:
  {
    marginLeft: spacingXl
  },

  // settings page and welcome

  flexDirection: {
    flexDirection: "column",
  },

  inputWidth: {
    width: "300px",
  },

  imagePosition: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
    position: "relative",
    [`@media screen and (min-width: ${breakpointXs}px) and (max-width: ${breakpointMd}px)`]:
    {
      minHeight: "100vh",
    },
  },
  column: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },

  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  helpIcon: {
    position: "relative",
    top: "7px",
    left: spacingXs,
    width: "20px",
  },
  priceRow: {
    [`@media screen and (min-height: ${breakpointXs}px) and (max-height: ${breakpointMd}px)`]:
      {
        marginLeft: "-35%",
      },
    [`@media screen and (min-height: ${breakpointMd}px) and (max-height: ${breakpointXl}px)`]:
      {
        marginLeft: "-15%",
      },
  },
  quantiyRow: {
    [`@media screen and (min-height: ${breakpointXs}px) and (max-height: ${breakpointMd}px)`]:
      {
        marginLeft: "5%",
      },
    [`@media screen and (min-height: ${breakpointMd}px) and (max-height: ${breakpointXl}px)`]:
      {
        marginLeft: "15%",
      },
    [`@media screen and (min-height: ${breakpointXl}px) and (max-height: ${breakpointXxl}px)`]:
      {
        marginLeft: "25%",
      },
  },
});

export { useStyles };
