import { SHA256, enc } from "crypto-js";
import { authentication, app } from "@microsoft/teams-js";
import axios from "axios";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";

// common params token&authorize
const redirectUri = window.location.origin + "/authEnd";
const clientId = process.env.REACT_APP_IDP_CLIENT_ID ?? ""; // TODO: Make a function from where to call all env configuration and also handle errors

// token params
const codeVerifier = generateCodeVerifier();
const grantType = "authorization_code";

// authorize params
const codeChallenge = generateCodeChallenge(codeVerifier);
const responseType = "code";
const scope = "urn:matrix42NewUX";
const codeChallengeMethod = "S256";

function getAuthorizeQueryParams() {
  return [
    `response_type=${responseType}`,
    `client_id=${clientId}`,
    `scope=${scope}`,
    `redirect_uri=${redirectUri}`,
    `code_challenge=${codeChallenge}`,
    `code_challenge_method=${codeChallengeMethod}`,
    `ESMDomain=${localStorage.getItem("ESMDomain")}`
  ].join("&");
}

function generateCodeVerifier() {
  const codeVerifier = generateRandomString(128);

  return codeVerifier;
}

function generateRandomString(length: number) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

function generateCodeChallenge(codeVerifier: string) {
  return base64URL(SHA256(codeVerifier));
}

function base64URL(codeVerifierWithSHA256: CryptoJS.lib.WordArray) {
  return codeVerifierWithSHA256
    .toString(enc.Base64)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

async function acquireTokenInLocalStorage(codeVerifier: string, redirectUri: string, clientId: string, grantType: string, authCode: string, userContext: ApprovalUserContext) : Promise<string> {
    const urlSearchParams = new URLSearchParams({
      grant_type: grantType,
      client_id: clientId,
      code_verifier: codeVerifier,
      code: authCode,
      redirect_uri: redirectUri
    });

    const url = `${userContext.getDomainUrl()}/m42services/api/sts/token`
    const result = await axios.post(url, urlSearchParams);

    return result.data.access_token;
  }

const AuthenticationService = {
  authenticateWithPKCEFlowAndGetToken: async function(userContext: ApprovalUserContext) {
    const authorizeUrl = `authStart?${getAuthorizeQueryParams()}`;
  
    await app.initialize();
    const thisShouldBeCode = await authentication.authenticate({        
        url: authorizeUrl,
        width: 400,
        height: 750
    })
    const token = acquireTokenInLocalStorage(codeVerifier, redirectUri, clientId, grantType, thisShouldBeCode, userContext)    

    return token;
  },
};

export default AuthenticationService;
