import { Button } from "@fluentui/react-components";
import { OrderState } from "../../interfaces/OrderState";
import { useContext } from "react";
import { ApprovalUserContext } from "../../interfaces/ApprovalUserContext";
import { UserContext } from "../../contexts/UserContext";
import { mapStateFromEnumToString } from "../../helpers/MapOrderStates";

function OrderFilterButton(props: { orderState: OrderState }) {
  const userContext = useContext<ApprovalUserContext>(UserContext);

  return (
    <div>
      <Button
        appearance={
          userContext.orderState === props.orderState ? "primary" : "secondary"
        }
        onClick={() => userContext.setOrderState(props.orderState)}
        shape="circular"
      >
        {mapStateFromEnumToString(props.orderState)}
      </Button>
    </div>
  );
}

export default OrderFilterButton;
