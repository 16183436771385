import { Button } from "@fluentui/react-button";
import { Link, Tooltip } from "@fluentui/react-components";
import { getOrderURL } from "../../services/Constants";
import { Open16Regular } from "@fluentui/react-icons";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";
function ExternalLinkButton(props: { objectId: string }) {
  const onClickEvent = (e: React.MouseEvent<HTMLElement>) =>
    e.stopPropagation();
  const userContext = useContext(UserContext);
  const localized = useLocalization() as LanguageStrings;

  return (
    <Link
      rel="noreferrer"
      target="_blank"
      href={getOrderURL(props.objectId, userContext.getDomainUrl())}
      onClick={onClickEvent}
    >
      <Tooltip
        withArrow
        content={localized.OpenInSelfServicePortal}
        relationship="label"
      >
        <Button
          appearance={"secondary"}
          icon={<Open16Regular />}
          iconPosition="after"
        >
          <>{localized.ViewinselfServicePortal} </>
        </Button>
      </Tooltip>
    </Link>
  );
}

export default ExternalLinkButton;
