import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
export const reactPlugin = new ReactPlugin();

// Workaround if we want to use latest appInsights package: export const reactPluginWorkaround = reactPlugin as unknown as ITelemetryPlugin

const instrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY;
if (instrumentationKey !== undefined && instrumentationKey !== null) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: true },
      },
    },
  });

  appInsights.loadAppInsights();
}
