import axios, { AxiosError } from "axios";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";
import { TokenService } from "./TokenService";
import { ErrorHandlingService } from "./ErrorHandlingService";
import { getSPSGlobalConfigurationParams} from "./Constants";
import { WidgetCommonGetDataParams } from "../interfaces/WidgetCommonGetDataParams";
import { SPSGlobalConfigurationType } from "../interfaces/SPSGlobalConfigurationType";


function handleException(e : any)
{
    if(e instanceof AxiosError){
        ErrorHandlingService.handleApiError(e);
    }
    else{
    ErrorHandlingService.handleException(e);
    }
}

function getWidgetCommonGetDataUrl (params: WidgetCommonGetDataParams){
  let url = `/getdata/${params.widgetid}/${params.objectid}?`;

  const queryParams = new URLSearchParams();
  if (params.alias) {
    queryParams.append('alias', params.alias);
  }
  if (params.archived !== undefined) {
    queryParams.append('archived', params.archived.toString());
  }
  if (params.loadLocalization !== undefined) {
    queryParams.append('loadlocalization', params.loadLocalization.toString());
  }
    url += queryParams.toString();

  return url;
}

export const WidgetCommonService =
{

    getAxiosInstance: function(userContext: ApprovalUserContext) {
    return axios.create({
      baseURL: `${userContext.getDomainUrl()}/m42services/api/WidgetCommon`,
      headers: {
        Authorization: TokenService.getAuthorizationHeader(),
        "content-type": "application/json",
        accept: "application/json",
      },
    });
  },

    getData: async function (userContext: ApprovalUserContext, params: WidgetCommonGetDataParams) {
    try {
      const response = await this.getAxiosInstance(userContext)
                .get(getWidgetCommonGetDataUrl(params));
      return response.data;
    } catch (e) {
      handleException(e)
      throw e; 
    }
  },

    getSPSGlobalConfiguration: async function(userContext: ApprovalUserContext){

        const widgetGetParam = {
            widgetid: getSPSGlobalConfigurationParams.widgetid,
            objectid: getSPSGlobalConfigurationParams.objectid,
            alias: getSPSGlobalConfigurationParams.alias
          }  as WidgetCommonGetDataParams;
          
        const result =  await this.getData(userContext, widgetGetParam) 
        return result.Entity as SPSGlobalConfigurationType;
    }

}