export const currencySymbols: Record<string, string> = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
}

export enum ActionType {
 Approve,
 Decline
}


export const headers = [
  { headerKey: "id", label: "ID" },
  { headerKey: "reqDate", label: "RequestDate" },
  { headerKey: "serviceName", label: "ServiceName" },
  { headerKey: "additionalInfo", label: "AdditionalInfo" },
  { headerKey: "state", label: "State" },
  { headerKey: "Recipient", label: "Recipient" },
  { headerKey: "Qty", label: "Qty" },
  { headerKey: "priceMonthly", label: "PriceMonthlyTableHeader" },
  { headerKey: "totalPrice", label: "TotalPrice" },
];

export const getSPSGlobalConfigurationParams = {
  widgetid: "2de78cfb-db24-c868-eddb-08d4af06aadc",
  objectid: "CC0EA0CD-A84A-487C-AE1D-A68EF33E7DE1",
  alias: "GlobalConfiguration.SPSGlobalConfigurationType"
} 

export const orderStates = {
  Pending: "Pending",
  PendingOther: "Pending Other",
  Declined: "Declined",
  Provisioned: "Provisioned",
  All: "All",
};

export const sortOptionsStrings = {
  OrderId: "ID",
  RequestDate: "Request Date",
  TotalPrice: "Total Price",
};

export const getOrderURL = (objectId: string, domainUrl: string) =>
  `${domainUrl}/wm/app-SelfServicePortal/nofilter/?view-options={"objectId":"${objectId}","archived":"0","type":"SPSSelfServiceOrderItemType","viewType":"preview"}`;

export const getAdditionalInfoURL = (
  formObjectId: string,
  formType: string,
  domainUrl: string
) =>
  `${domainUrl}/wm/app-SelfServicePortal/nofilter/?view-options={"objectId":"${formObjectId}","archived":"0","type":"${formType}","viewType":"preview"}`;

export const helpMatrix42ApprovalAppLink =
  "https://help.matrix42.com/020_ESM/10_Service_Management/050Add-Ons/MX42_Approvals_(extension)";

export const topOrdersCount = 10;

export const defaultSkipOrdersNumber = 0;
