import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableRow,
  mergeClasses,
} from "@fluentui/react-components";
import { Order } from "../../../../interfaces/Order";
import { useStyles } from "../../../../styles/styleClasses";
import { currencySymbols } from "../../../../services/Constants";
import { useContext, useEffect, useState } from "react";
import { OrdersContext } from "../../../../contexts/OrdersContext";
import { OrderState } from "../../../../interfaces/OrderState";
import React from "react";
import { Booking } from "../../../../interfaces/Booking";
import {} from "@fluentui/react-components";
import * as microsoftTeams from "@microsoft/teams-js";
import { flattenBookingsTree, flattenBookingsTreeWithDepth } from "../../../../helpers/TreeHelper";

function OrderMultipleBookingBody(props: {
  order: Order;
  onBookingUpdate: (bookings: Booking[]) => void;
}) {
  const styles = useStyles();
  const orderManagementContext = useContext(OrdersContext);
  const [initialized, setInitialized] = useState(false);
  const [appTheme, setAppTheme] = useState<string>();

  const stopPropagationOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  microsoftTeams.app
    .getContext()
    .then((context: microsoftTeams.app.Context) =>
      setAppTheme(context.app.theme)
    );
  useEffect(() => {
    setInitialized(true);
  }, []);

 
  const markChildren = (bookings:Booking[], isApproved:boolean) =>
  {
    flattenBookingsTree(bookings).map(item => {item.IsCheckedForApproval = isApproved; 
        orderManagementContext.updateSelectedBookings(
          props.order.ObjectId,
          item.BookingId
        )
    });
  }

  const onChange = (booking: Booking) => {
    orderManagementContext.updateSelectedBookings(
      props.order.ObjectId,
      booking.BookingId
    );
    booking.IsCheckedForApproval = !booking.IsCheckedForApproval;
    booking.Children && markChildren(booking.Children, booking.IsCheckedForApproval);
    props.onBookingUpdate(props.order.Booking);
  };
  const getTableRowCheckboxThemeColors = (
    isChecked: boolean,
    theme: string | undefined
  ) => {
    if(!props.order.IsMultipleBooking)
    {
      return undefined;
    }
    switch (theme) {
      case "default":
        return isChecked
          ? styles.multipleBookingApprovedBackground
          : styles.multipleBookingDeclinedBackground;
        break;
      case "dark":
        return isChecked
          ? styles.multipleBookingApprovedBackgroundDarkTheme
          : styles.multipleBookingDeclinedBackgroundDarkTheme;
        break;
      case "contrast":
        return isChecked
          ? styles.multipleBookingApprovedBackgroundDarkTheme
          : styles.multipleBookingDeclinedBackgroundDarkTheme;
        break;
    }
  };

  return (
    <>
      {" "}
      <Table
        className={styles.table}
        noNativeElements
        aria-label="Requests List"
      >
        <TableBody
          className={mergeClasses(
            styles.borderNeutral1,
            styles.borderRadiusMd,
            styles.mt0
          )}
        >
          {initialized &&
            flattenBookingsTreeWithDepth(props.order.Booking).map((item, index) => (
              <TableRow
                className={getTableRowCheckboxThemeColors(
                  item.booking.IsCheckedForApproval,
                  appTheme
                )}
                key={index}
              >
                <TableCell className={item.isChild ? styles.bookingTreeChild : undefined}>
                  {props.order.IsMultipleBooking && 
                    <Checkbox 
                    aria-label="orderCheckbox"
                    checked={item.booking.IsCheckedForApproval}
                    onClick={stopPropagationOnClick}
                    disabled={
                      props.order.StateId !== OrderState.Pending || item.isChild || !props.order.IsMultipleBooking ? true : false
                    }
                    onChange={() => {
                      if(!item.isChild) 
                        onChange(item.booking);
                    }}
                  />}
                  <TableCellLayout
                    truncate
                    description={item.booking.ServiceItemNumber}
                    appearance="primary"   
                  >
                    {item.booking.Name}
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout
                    className={mergeClasses(styles.flexNone, styles.alignTextRight)}
                    description={item.booking.Payment}
                    appearance="primary"
                  >
                    {item.booking.Price} {currencySymbols[item.booking.CurrencyCode]}
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}

export default OrderMultipleBookingBody;
