import { tokens } from "@fluentui/react-components";

// General

export const toolbarHeight = "70px";
export const toolbarHeightMobile = "71px";
export const spacingXs = tokens.spacingVerticalXS;
export const spacingSm = tokens.spacingVerticalS;
export const spacingMd = tokens.spacingVerticalM;
export const spacingLg = tokens.spacingVerticalL;
export const spacingXl = tokens.spacingVerticalXL;
export const spacingXxl = tokens.spacingVerticalXXL;

// Colors

export const bodyBg = tokens.colorNeutralBackground3;

// Breakpoints

export const breakpointXs = 0;
export const breakpointXsm = 324;
export const breakpointSm = 576;
export const breakpointMd = 768;
export const breakpointLg = 992;
export const breakpointXl = 1200;
export const breakpointXxl = 1800;
