import { Button, mergeClasses } from "@fluentui/react-components";
import { useContext } from "react";
import { Dismiss16Filled } from "@fluentui/react-icons";
import { useStyles } from "../../../styles/styleClasses";
import { ApprovalUserContext } from "../../../interfaces/ApprovalUserContext";
import { UserContext } from "../../../contexts/UserContext";
import ApprovalService from "../../../services/ApprovalService";
import { ErrorHandlingService } from "../../../services/ErrorHandlingService";
import { OrdersContext } from "../../../contexts/OrdersContext";
import { DecideSelectedButtonProps } from "../../../interfaces/DecideSelectedButtonProps";
import {
  LanguageStrings,
  useLocalization,
} from "../../../contexts/LocalizationContext";

function DeclineSelectedButton({
  overrideOnClick,
  comment = "Declined from approval app.",
  onClickCallback,
  disabled = false,
}: DecideSelectedButtonProps) {
  const styles = useStyles();
  const userContext = useContext<ApprovalUserContext>(UserContext);
  const orderManagementContext = useContext(OrdersContext);
  const localized = useLocalization() as LanguageStrings;

  const onClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    if (overrideOnClick) {
      overrideOnClick();
    } else {
      handleClick();
    }
    if (onClickCallback) {
      onClickCallback();
    }
    e.stopPropagation();
  };

  function handleClick() {
    let selectedObjectIds: string[] = [];
    orderManagementContext.orders.map((order) => {
      if (order.IsSelected) {
        selectedObjectIds = selectedObjectIds.concat(order.ObjectId);
      }
    });

    ApprovalService.declineMultipleOrders(
      userContext,
      selectedObjectIds,
      comment
    )
      .then(() => {
        orderManagementContext.popOrdersAfterDecide(selectedObjectIds);
      })
      .catch((error) => {
        ErrorHandlingService.handleApiError(error);
      });
  }

  if (orderManagementContext.numberOfSelectedOrders !== 0)
    return (
      <Button
        onClick={onClickEvent}
        className={mergeClasses(styles.textDanger)}
        icon={<Dismiss16Filled />}
        disabled={disabled}
      >
        {localized.Decline} ({orderManagementContext.numberOfSelectedOrders})
      </Button>
    );
  else return <></>;
}

export default DeclineSelectedButton;
