import React, { ReactNode } from "react";
import { OrdersManagement } from "../interfaces/OrdersManagement";
import { useOrdersManagement } from "../hooks/useOrdersManagement";

export const OrdersContext = React.createContext<OrdersManagement>(
  {} as OrdersManagement
);

type Params = {
  children: ReactNode;
  initialValues?: OrdersManagement;
};

export const OrdersManagementProvider = ({
  children,
  initialValues,
}: Params) => {
  let ordersMgmContext: OrdersManagement;

  if (initialValues === undefined) {
    ordersMgmContext = useOrdersManagement();
  } else {
    ordersMgmContext = initialValues;
  }

  return (
    <OrdersContext.Provider value={ordersMgmContext}>
      {children}
    </OrdersContext.Provider>
  );
};
