import { format } from "date-fns";
import { Order } from "../interfaces/Order";
import { getImageUrlSource } from "./GetServiceImageUrl";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";
import { orderStates } from "../services/Constants";
import { OrderState } from "../interfaces/OrderState";
import { Booking } from "../interfaces/Booking";
import { BookingApprovalStatus } from "../interfaces/BookingApprovalStatus";

export function mapOrderData(userContext: ApprovalUserContext, orderData: any) {
  const orderList = [] as Order[];
  let ordersDto = {} as Order;

  function mapBookingsRecursive(bookings: any, isPendingOrder: boolean) {
    const childrenBookingsDto = [] as Booking[];

    bookings.forEach((booking: any) => {
      const childBookingDto = {} as Booking 
      childBookingDto.Name = booking.Name;
      childBookingDto.BookingId = booking.Id;
      childBookingDto.BookingObjectId = booking.ObjectId;
      childBookingDto.ItemNumber = booking.ItemNumber;
      childBookingDto.ServiceItemNumber = booking.Service.ItemNumber;
      childBookingDto.Payment = booking.Service.Payment;
      childBookingDto.Quantity = booking.Quantity;
      childBookingDto.ImageUrl = getImageUrlSource(userContext, booking.ImageUrl);
      childBookingDto.StateId = booking.ApprovalStatus;
      childBookingDto.IsCheckedForApproval = booking.ApprovalStatus === BookingApprovalStatus.Approved || isPendingOrder;
      childBookingDto.Price = booking.Price?.Value || "0.00";
      childBookingDto.CurrencyCode = booking.Price?.CC || "";
      

      if (Object.prototype.hasOwnProperty.call(booking.AdditionalData, "CustomFormObjectID") &&
        Object.prototype.hasOwnProperty.call(booking.AdditionalData, "CustomFormEntityName")) {
        childBookingDto.AdditionalData = {
          FormObjectId: booking.AdditionalData.CustomFormObjectID,
          FormEnitityName: booking.AdditionalData.CustomFormEntityName,
        };
      }

      if (booking.Children) {
        childBookingDto.Children = mapBookingsRecursive(booking.Children, isPendingOrder);
      }

      childrenBookingsDto.push(childBookingDto);
    });

    return childrenBookingsDto;
  }

  orderData.Orders.forEach((order: any) => {
    ordersDto.Booking = mapBookingsRecursive(order.Bookings, order.StateId === OrderState.Pending);
    ordersDto.IsMultipleBooking = order.Bookings.length > 1;
    ordersDto.IsSelected = false;
    ordersDto.Id = order.Id;
    ordersDto.ObjectId = order.ObjectId;
    ordersDto.CreatedDate = format(new Date(order.CreatedDate), "dd MMM yyyy hh:mm a");
    ordersDto.DecidedDate = format(new Date(order.DecidedDate), "dd MMM yyyy hh:mm a");
    const decideAllowed = order.DecideAllowed || false;
    ordersDto.StateId = order.StateId === OrderState.Pending && !decideAllowed
      ? OrderState.PendingOther
      : order.StateId;
    ordersDto.State = order.State === orderStates.Pending && !decideAllowed
      ? orderStates.PendingOther
      : order.State;
    ordersDto.Requestor = order.Requestor;
    ordersDto.Recipient = order.Recipient;
    ordersDto.CostCenter = order.CostCenterName;
    ordersDto.ItemNumber = order.ItemNumber;
    ordersDto.TotalCosts = order.TotalCosts;
    ordersDto.CurrencyCode = order.TotalCosts.Total.CC;
    ordersDto.Price = order.TotalCosts.Total.Value !== undefined
      ? order.TotalCosts.Total.Value
      : "0.00";

    ordersDto.Booking.forEach((bookings: any) => {
      ordersDto.Name = ordersDto.Name !== undefined
        ? `${ordersDto.Name}, ${bookings.Name}`
        : bookings.Name;
    });

    const orderWasAdded = orderList.some((obj) => obj.Id === order.Id);

    if (!orderWasAdded) {
      orderList.push(ordersDto);
    }

    ordersDto = {} as Order;
  });

  return orderList;
}
