import { Spinner, mergeClasses, Image } from "@fluentui/react-components";
import { useStyles } from "../../../../styles/styleClasses";
import { Order } from "../../../../interfaces/Order";
import OrderIdentification from "./contentTitleFields/OrderIdentification";
import OrderStateBadge from "./contentTitleFields/OrderStateBadge";
import { useEffect, useState } from "react";
import ApprovalService from "../../../../services/ApprovalService";
import { ErrorHandlingService } from "../../../../services/ErrorHandlingService";
import { Text } from "@fluentui/react-components";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../contexts/LocalizationContext";
function OrderModalContentTitle(props: { order: Order }) {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [serviceImage, setServiceImage] = useState("");
  const handleImageLoaded = () => {
    setIsLoading(false);
  };
  const localized = useLocalization() as LanguageStrings;

  useEffect(() => {
    if (props.order.Booking[0].ImageUrl.includes("/api/")) {
      ApprovalService.getExternalImageUrl(props.order.ImageUrl)
        .then((resp: Blob | MediaSource) => {
          setServiceImage(URL.createObjectURL(resp));
        })
        .catch((error) => {
          ErrorHandlingService.handleApiError(error);
        });
    } else {
      setServiceImage(props.order.Booking[0].ImageUrl);
    }
  }, []);
  return (
    <div>
      <div
        className={mergeClasses(
          styles.dFlex,
          styles.justifyBetween,
          styles.alignCenter,
          styles.pLg,
          styles.responsiveTitle
        )}
      >
        <div
          className={mergeClasses(
            styles.dFlex,
            styles.alignCenter,
            styles.overflowHidden
          )}
        >
          <div
            className={mergeClasses(
              styles.image,
              styles.imageLg,
              styles.dFlex,
              styles.justifyCenter,
              styles.alignCenter
            )}
          >
            {isLoading && <Spinner />}
            <Image
              as="img"
              onLoad={handleImageLoaded}
              shape="rounded"
              src={serviceImage}
            />
          </div>
          <OrderIdentification order={props.order} />
        </div>
        <div className={styles.responsiveHeading}>
          <OrderStateBadge stateId={props.order?.StateId} />
        </div>
      </div>
      {props.order.IsMultipleBooking === true && (
        <div>
          {" "}
          <Text className={styles.mlLg}>
            {localized.GreenApprovedRedDeclined}
          </Text>
        </div>
      )}
    </div>
  );
}

export default OrderModalContentTitle;
