import { Checkbox } from "@fluentui/react-components";
import { Order } from "../../interfaces/Order";
import { useContext } from "react";
import { OrdersContext } from "../../contexts/OrdersContext";

function OrderCheckbox(props: { item: Order }) {
  const orderManagementContext = useContext(OrdersContext);

  const stopPropagationOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <Checkbox
      aria-label="orderCheckbox"
      disabled={props.item.State !== "Pending"}
      checked={props.item.IsSelected}
      onClick={stopPropagationOnClick}
      onChange={() => {
        props.item.IsSelected = !props.item.IsSelected;
        orderManagementContext.updateSelectedOrders(
          props.item.ObjectId,
          props.item.IsSelected
        );
      }}
    />
  );
}

export default OrderCheckbox;
