import { Dialog, mergeClasses } from "@fluentui/react-components";
import { ErrorBoundary } from "react-error-boundary";
import { useContext, useEffect, useState } from "react";
import { DisplayMode } from "../../interfaces/DisplayMode";
import { ErrorHandlingService } from "../../services/ErrorHandlingService";
import { useStyles } from "../../styles/styleClasses";
import Welcome from "../views/Welcome";
import ApprovalsToolbar from "../views/ApprovalsToolbar";
import { UserContext } from "../../contexts/UserContext";
import { OrdersManagementProvider } from "../../contexts/OrdersContext";
import DisplayOrderData from "../views/DisplayOrderData";
import { AskForReasonModal } from "../views/AskForReasonModal";
import { ActionType } from "../../services/Constants";
import { WidgetCommonService } from "../../services/WidgetCommonService";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function ParentTab() {
  const [domainUrl, setDomain] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 768 ? false : true
  );
  const [isLoading, setIsLoading] = useState(true);
  const styles = useStyles();
  const userContext = useContext(UserContext);
  const localized = useLocalization() as LanguageStrings;
  const [requireReasonDialog, setReqireReasonDialog] = useState(false);
  const [actionType, setActionType] = useState(ActionType.Approve);
  useEffect(() => {
    setDomain(userContext.getDomainUrl());
    setToken(localStorage.getItem("ESMToken"));
    setIsLoading(false);
    window.addEventListener("resize", handleResize);
  }, [token]);
  const [requireApprovalReason, setRequireApprovalReason] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      const config = await WidgetCommonService.getSPSGlobalConfiguration(
        userContext
      );
      setRequireApprovalReason(
        config.SPSGlobalConfigurationClassServiceCatalog.AskApprovalReason
      );
    };

    if (isLoading || domainUrl === null || token === null) return;

    fetchConfig().catch(ErrorHandlingService.handleException);
  }, [isLoading, domainUrl, token]);

  useEffect(() => {
    handleResize();
  }, []);

  // choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 768) {
      userContext.setDisplayMode(DisplayMode.Cards);
      setIsMobile(true);
    } else {
      userContext.setDisplayMode(DisplayMode.Table);
      setIsMobile(false);
    }
  };

  return (
    <div className={styles.page}>
      <ErrorBoundary
        fallback={<div role="alert">{localized.SomethingWentWrong}</div>}
        onError={ErrorHandlingService.handleException}
      >
        {isLoading === false && (
          <>
            {domainUrl === null || token === null ? (
              <Welcome setToken={setToken} />
            ) : (
              <>
                <OrdersManagementProvider>
                  <ApprovalsToolbar
                    isMobile={isMobile}
                    domainUrl={domainUrl}
                    askForReason={(actionType: ActionType) => {
                      setActionType(actionType);
                      setReqireReasonDialog(true);
                    }}
                    requireApprovalReason={requireApprovalReason}
                  />
                  <div className={styles.toolbarOffset}>
                    <div
                      className={mergeClasses(
                        styles.container,
                        styles.mxAuto,
                        styles.pXl
                      )}
                    >
                      <DisplayOrderData
                        requireApprovalReason={requireApprovalReason}
                      />
                    </div>
                  </div>
                  <Dialog
                    open={requireReasonDialog}
                    onOpenChange={() => setReqireReasonDialog(false)}
                  >
                    <AskForReasonModal
                      isApprovalAction={actionType === ActionType.Approve}
                      onClose={() => setReqireReasonDialog(false)}
                    />
                  </Dialog>
                </OrdersManagementProvider>
              </>
            )}
          </>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default ParentTab;
