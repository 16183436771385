import { Link } from "@fluentui/react-components";
import { useStyles } from "../../styles/styleClasses";
import { useContext } from "react";
import { OrdersContext } from "../../contexts/OrdersContext";
import { clearSelectedAllData } from "../../helpers/ClearSelectedAllData";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function ClearSelectionButton() {
  const styles = useStyles();
  const orderManagementContext = useContext(OrdersContext);
  const localized = useLocalization() as LanguageStrings;

  if (orderManagementContext.numberOfSelectedOrders !== 0)
    return (
      <Link
        as="button"
        className={styles.mlSm}
        onClick={() => {
          orderManagementContext.updateSelectAll(false);
          clearSelectedAllData(orderManagementContext);
        }}
      >
        {localized.ClearSelection}
      </Link>
    );
  else return <></>;
}

export default ClearSelectionButton;
