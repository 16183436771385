import { mergeClasses } from "@fluentui/react-components";
import { useStyles } from "../../../../styles/styleClasses";
import { Text, Image } from "@fluentui/react-components";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../contexts/LocalizationContext";

export function OrderModalHeaderField(props: { title: string }) {
  const localized = useLocalization() as LanguageStrings;

  const styles = useStyles();
  return (
    <div
      className={mergeClasses(
        styles.dFlex,
        styles.justifyBetween,
        styles.alignCenter,
        styles.pLg,
        styles.responsiveTitle
      )}
    >
      <div
        className={mergeClasses(
          styles.dFlex,
          styles.alignCenter,
          styles.justifyBetween
        )}
      >
        <div
          className={mergeClasses(
            styles.image,
            styles.imageLg,
            styles.alignCenter
          )}
        >
          <Image
            as="img"
            alt="Approvals icon"
            shape="rounded"
            src="/static/img/ApprovalsImage.png"
          />
        </div>

        <div className={mergeClasses(styles.plMd, styles.overflowHidden)}>
          <Text
            size={500}
            as="h4"
            weight="semibold"
            className={mergeClasses(
              styles.textOverflow,
              styles.overflowHidden,
              styles.textNeutralForeground1,
              styles.dFlex,
              styles.mNone
            )}
          >
            {props.title}
          </Text>
          <Text block className={styles.textNeutralForeground3}>
            {localized.Approvals}
          </Text>
        </div>
      </div>
    </div>
  );
}
