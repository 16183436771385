import { useContext, useEffect, useState } from "react";
import { ApprovalUserContext } from "../../interfaces/ApprovalUserContext";
import { UserContext } from "../../contexts/UserContext";
import { DisplayMode } from "../../interfaces/DisplayMode";
import { ApprovalTable } from "./ApprovalTable";
import { ApprovalCards } from "./ApprovalCards";
import { Order } from "../../interfaces/Order";
import { Dialog, Spinner } from "@fluentui/react-components";
import OrderModel from "./orderModal/OrderModal";
import NoOrders from "./NoOrders";
import { OrdersContext } from "../../contexts/OrdersContext";
import * as microsoftTeams from "@microsoft/teams-js";
import { ErrorHandlingService } from "../../services/ErrorHandlingService";

function DisplayOrderData(props: { requireApprovalReason: boolean }) {
  const lastNotificationStorageKey = "lastNotification";
  const userContext = useContext<ApprovalUserContext>(UserContext);
  const {
    orders: items,
    isLoading,
    setOrders,
    setBookings,
  } = useContext(OrdersContext);
  const [open, setOpen] = useState(false);
  const [dialogOrder, setDialogOrder] = useState<Order>({} as Order);
  const [displayNotificationModal, setDisplayNotificationModal] = useState(true);
  

  function refreshOrder(order: Order) {
    if (items !== null && items !== undefined) {
      const newList = items.filter(
        (item) => item.ItemNumber !== order?.ItemNumber
      );
      setOrders({ newOrdersList: newList, itemWasSelected: true });
    }
  }

  function setDialogData(order: Order) {
    setDialogOrder(order);
    setOpen(true);
  }

  function closeDialog() {
    setBookings({});
    setOpen(false);
  }

  useEffect(() => {
    microsoftTeams.app.getContext().then((context) => {
      if (displayNotificationModal) {
        try {
          const orderId = context?.page?.subPageId;
  
          if (!orderId || items.length === 0) {
            return;
          }
      
          setDisplayNotificationModal(false);
          const res = items.find((x) => x.ObjectId === orderId);
          if(localStorage.getItem(lastNotificationStorageKey) === orderId){
            return;
          }
          localStorage.setItem(lastNotificationStorageKey,orderId);
          if (res) {
            setDialogData(res);
          }
        } catch (error) {
          ErrorHandlingService.handleException(error as Error);
        }
      }
    });
  }, [items, displayNotificationModal]);
  

  function getDisplayModeComponent() {
    if (userContext.displayMode === DisplayMode.Table) {
      return (
        <ApprovalTable
          rowData={items}
          setDialogData={setDialogData}
          refreshOrder={refreshOrder}
          sortSettings={userContext.getSortOptions()}
          requireApprovalReason = {props.requireApprovalReason}
        />
      );
    } else {
      return (
        <ApprovalCards
          orderData={items}
          setDialogData={setDialogData}
          refreshOrder={refreshOrder}
          requireApprovalReason={props.requireApprovalReason}
        />
      );
    }
  }

  if (isLoading) {
    return (
      <div>
        <Spinner size="medium" />
      </div>
    );
  } else if (items?.length === 0) {
    return <NoOrders />;
  } else {
    return (
      <div>
        {getDisplayModeComponent()}

        <Dialog open={open} onOpenChange={() => closeDialog()}>
          <OrderModel
            order={dialogOrder}
            refreshOrder={() => refreshOrder(dialogOrder)}
            closeDialog={closeDialog}
            requireApprovalReason={props.requireApprovalReason}
          />
        </Dialog>
      </div>
    );
  }
}

export default DisplayOrderData;
