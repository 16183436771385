import { Booking } from "../interfaces/Booking";

export function  flattenBookingsTree(data: Booking[]): Booking[] {
    return data ? data.flatMap((item: Booking) => {
      return item.Children && item.Children !== null
        ? [item, ...flattenBookingsTree(item.Children)]
        : [item];
    }) : [];
  }


export  function flattenBookingsTreeWithDepth(data: Booking[], depth = 0): {booking:Booking, isChild:boolean}[] {
    return data ? data.flatMap((item: Booking) => {
      return item.Children && item.Children !== null
        ? [{booking:item, isChild: depth > 0}, ...flattenBookingsTreeWithDepth(item.Children, depth + 1)]
        : [{booking:item, isChild: depth > 0}];
    }) : []; 
  } 
 