import {
  FluentProvider,
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsLightTheme,
  Theme,
} from "@fluentui/react-components";
import * as microsoftTeams from "@microsoft/teams-js";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthStart from "./components/controls/AuthStart";
import AuthEnd from "./components/controls/AuthEnd";
import { useState } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/Telemetry";
import ParentTab from "./components/tabs/ParentTab";
import { UserContextProvider } from "./contexts/UserContextProvider";
import { LocalizationProvider } from "./contexts/LocalizationContext";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
function App() {
  const [appTheme, setAppTheme] = useState<Theme>(teamsLightTheme);

  // Initialize the Microsoft Teams SDK
  microsoftTeams.app.initialize();

  function SetTheme(theme: string) {
    switch (theme) {
      case "default":
        setAppTheme(teamsLightTheme);
        break;
      case "dark":
        setAppTheme(teamsDarkTheme);
        break;
      case "contrast":
        setAppTheme(teamsHighContrastTheme);
        break;
    }
  }

  microsoftTeams.app
    .getContext()
    .then((context: microsoftTeams.app.Context) => SetTheme(context.app.theme));

  microsoftTeams.app.registerOnThemeChangeHandler((theme: string) =>
    SetTheme(theme)
  );

  // Display the app home page hosted in Teams
  return (
    <LocalizationProvider>
      <AppInsightsContext.Provider value={reactPlugin}>
        <FluentProvider theme={appTheme}>
          <BrowserRouter>
            <UserContextProvider>
              <Routes>
                <Route path="/" element={<Navigate replace to="/orders" />} />
                {/* Temporary as a failback for old manifest users */}
                <Route
                  path="/pending"
                  element={<Navigate replace to="/orders" />}
                />
                <Route
                  path="/declined"
                  element={<Navigate replace to="/orders" />}
                />
                <Route
                  path="/approved"
                  element={<Navigate replace to="/orders" />}
                />
                <Route
                  path="/all"
                  element={<Navigate replace to="/orders" />}
                />
                {/*  */}
                <Route path="/orders" element={<ParentTab />} />
                <Route path="/authStart" element={<AuthStart />} />
                <Route path="/authEnd" element={<AuthEnd />} />
              </Routes>
            </UserContextProvider>
          </BrowserRouter>
        </FluentProvider>
      </AppInsightsContext.Provider>
    </LocalizationProvider>
  );
}
export default App;
