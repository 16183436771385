import { app, authentication } from "@microsoft/teams-js";

function AuthEnd() {
    app.initialize().then(() => {
        const authCode = getAuthCode(); 
        authentication.notifySuccess(authCode ?? "");
    });

    function getAuthCode(): string {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const authCode = url.searchParams.get("code")?.replace("\"", "") ?? "";
        
        return authCode;
    }
    
    return (<div></div>);
}

export default AuthEnd;