import { Text } from "@fluentui/react-components";
import { useStyles } from "../../../../../styles/styleClasses";
import { mergeClasses } from "@fluentui/react-components";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../../contexts/LocalizationContext";

function CostCenter(props: { costCenter: string }) {
  const styles = useStyles();
  const localized = useLocalization() as LanguageStrings;

  return (
    <div className={styles.overflowHidden}>
      <div className={styles.pbXs}>
        <Text
          block
          truncate
          className={mergeClasses(
            styles.textNeutralForeground3,
            styles.textOverflow,
            styles.overflowHidden
          )}
        >
          {localized.CostCenter}
        </Text>
      </div>
      <Text
        block
        truncate
        weight="semibold"
        className={mergeClasses(
          styles.textNeutralForeground1,
          styles.textOverflow,
          styles.overflowHidden
        )}
      >
        {props.costCenter}
      </Text>
    </div>
  );
}

export default CostCenter;
