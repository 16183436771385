import { OrdersManagement } from "../interfaces/OrdersManagement";

export function clearSelectedAllData(orderManagement: OrdersManagement) {
  orderManagement.setOrders({
    newOrdersList: orderManagement.orders.map((element) => {
      if (element.IsSelected === true) {
        return { ...element, IsSelected: false };
      } else {
        return element;
      }
    }),
    itemWasSelected: false,
  });
}
