/// Equivalent with ESM state filter codes
export enum OrderState {
  Pending = 1040,
  Approved = 1042,
  Provisioned = 1045,
  Declined = 1043,
  All,
  PendingOther = 9999,

}
