import { mergeClasses, Tooltip, Text } from "@fluentui/react-components";
import { currencySymbols } from "../../services/Constants";
import { Order } from "../../interfaces/Order";
import { useStyles } from "../../styles/styleClasses";
import { CostStructure, TotalCosts } from "../../interfaces/TotalCosts";
import { calculateQty } from "../../helpers/QtyCalculator";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

export default function PricingBlock(props: {
  order: Order;
  totalCosts: TotalCosts;
}) {
  const classes = useStyles();
  const localized = useLocalization() as LanguageStrings;

  function displayTwoDigitsValue(value: number) {
    const formattedValue = value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return <>{formattedValue}</>;
  }

  function checkPriceValueIsUndefined(cost: CostStructure) {
    if (cost !== undefined) {
      return (
        <>
          {" "}
          {cost.Value === undefined ? (
            <>0.00</>
          ) : (
            <>{displayTwoDigitsValue(cost.Value)}</>
          )}{" "}
          {currencySymbols[props.order.CurrencyCode]}
        </>
      );
    }
    return <>0.00 {currencySymbols[props.order.CurrencyCode]}</>;
  }

  return (
    <div
      className={mergeClasses(
        classes.dFlex,
        classes.justifyBetween,
        classes.pricingOuter
      )}
    >
      <div
        className={mergeClasses(
          classes.dFlex,
          classes.alignCenter,
          classes.gapSm,
          classes.pricingInner,
          classes.overflowHidden
        )}
      >
        <div className={mergeClasses(classes.prSm, classes.priceBlock)}>
          <Tooltip
            withArrow
            positioning="above"
            content={localized.Quantity}
            relationship="label"
          >
            <Text
              block
              truncate
              align="center"
              className={mergeClasses(
                classes.textNeutralForeground3,
                classes.textResponsive
              )}
            >
              <span className={classes.hideXs}>{localized.Qty}:</span>
              <span className={classes.showXs}>{localized.Quantity}:</span>
            </Text>
          </Tooltip>
          <Text
            truncate
            block
            align="end"
            weight="semibold"
            className={classes.textNeutralForeground1}
          >
            {calculateQty(props.order)}
          </Text>
        </div>
        <div
          className={mergeClasses(classes.priceBlock, classes.overflowHidden)}
        >
          <Text
            block
            truncate
            className={mergeClasses(
              classes.textOverflow,
              classes.overflowHidden,
              classes.textNeutralForeground3,
              classes.textResponsive
            )}
          >
            {localized.PriceMonthlyTableHeader}:
          </Text>
          <Text
            truncate
            align="end"
            block
            weight="semibold"
            className={classes.textNeutralForeground1}
          >
            <>
              {checkPriceValueIsUndefined(
                props.totalCosts.MonthlyRecurringCosts
              )}
            </>
            <Text truncate className={classes.textNeutralForeground3}>
              {" "}
              {localized.Hire}
            </Text>
          </Text>
        </div>
        <div
          className={mergeClasses(
            classes.plSm,
            classes.priceBlock,
            classes.overflowHidden
          )}
        >
          <Text
            block
            truncate
            className={mergeClasses(
              classes.textOverflow,
              classes.overflowHidden,
              classes.textNeutralForeground3,
              classes.textResponsive
            )}
          >
            {localized.OnTimeFee}
          </Text>
          <Text
            truncate
            align="end"
            block
            weight="semibold"
            className={classes.textNeutralForeground1}
          >
            <>
              {checkPriceValueIsUndefined(
                props.totalCosts.TotalPurchaseAndOneTimeCosts
              )}
            </>
          </Text>
        </div>
      </div>
      <div
        className={mergeClasses(
          classes.priceBlockTotal,
          classes.overflowHidden
        )}
      >
        <Text
          block
          truncate
          align="end"
          className={mergeClasses(
            classes.textOverflow,
            classes.overflowHidden,
            classes.textNeutralForeground3
          )}
        >
          {localized.TotalFirstYear}:
        </Text>
        <Text
          block
          truncate
          align="end"
          weight="semibold"
          className={classes.textNeutralForeground1}
        >
          <>{checkPriceValueIsUndefined(props.totalCosts.Total)}</>
        </Text>
      </div>
    </div>
  );
}
