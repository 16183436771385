import { Button, Tooltip, mergeClasses } from "@fluentui/react-components";
import { AppsList20Regular, GridKanban20Regular } from "@fluentui/react-icons";
import { DisplayMode } from "../../interfaces/DisplayMode";
import { useStyles } from "../../styles/styleClasses";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function ChangeViewDialog() {
  const styles = useStyles();
  const userContext = useContext(UserContext);
  const localized = useLocalization() as LanguageStrings;

  function toggleView() {
    if (userContext.displayMode === DisplayMode.Cards) {
      userContext.setDisplayMode(DisplayMode.Table);
    } else {
      userContext.setDisplayMode(DisplayMode.Cards);
    }
  }
  return (
    <Tooltip
      withArrow
      positioning="before"
      content={
        userContext.displayMode === DisplayMode.Cards
          ? localized.SwitchToListView
          : localized.SwitchToGridView
      }
      relationship="label"
    >
      <Button
        aria-label="ChangeView"
        className={mergeClasses(styles.mrSm, styles.mlSm)}
        onClick={toggleView}
        icon={
          userContext.displayMode === DisplayMode.Cards ? (
            <AppsList20Regular />
          ) : (
            <GridKanban20Regular />
          )
        }
      />
    </Tooltip>
  );
}

export default ChangeViewDialog;
