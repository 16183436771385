import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";
import { useStyles } from "../../styles/styleClasses";
import { Image, Text, mergeClasses } from "@fluentui/react-components";
export default function NoOrders() {
  const localized = useLocalization() as LanguageStrings;

  const styles = useStyles();

  return (
    <div
      className={mergeClasses(
        styles.imagePosition,
        styles.gridLayout,
        styles.noTouch
      )}
    >
      <div className={styles.column}>
        <Image as="img" alt="Approvals icon" src="/static/img/no_orders.png" />
      </div>
      <div className={styles.column}>
        <Text as="h2" truncate weight="bold">
          {localized.AssignedRequest}
        </Text>
      </div>
      <div className={styles.column}>
        <Text> {localized.EnjoyTimeOff}</Text>
      </div>
    </div>
  );
}
