import { Text } from "@fluentui/react-components";
import { useStyles } from "../../../../../styles/styleClasses";
import { mergeClasses } from "@fluentui/react-components";
import AdditionalInfo from "../../../../controls/AdditionalInfo";
import { AdditionalData } from "../../../../../interfaces/AdditionalData";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../../contexts/LocalizationContext";

function AdditionalDataField(props: { data: AdditionalData | undefined }) {
  const styles = useStyles();
  const localized = useLocalization() as LanguageStrings;

  return (
    <div className={styles.overflowHidden}>
      <div className={styles.pbXs}>
        <Text truncate className={styles.textNeutralForeground3}>
          {localized.AdditionalInformation}
        </Text>
      </div>
      <div className={styles.dFlex}>
        {props.data !== undefined ? (
          <AdditionalInfo formData={props.data} />
        ) : (
          <Text
            block
            truncate
            weight="semibold"
            className={mergeClasses(
              styles.textNeutralForeground1,
              styles.textOverflow,
              styles.overflowHidden,
              styles.dFlex
            )}
          >
            -
          </Text>
        )}
      </div>
    </div>
  );
}

export default AdditionalDataField;
