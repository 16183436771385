import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { ApprovalUserContext } from "../../interfaces/ApprovalUserContext";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
  mapStateFromEnumToString,
  mapStateFromStringToEnum,
} from "../../helpers/MapOrderStates";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function OrderFilterDropdown() {
  const userContext = useContext<ApprovalUserContext>(UserContext);
  const localized = useLocalization() as LanguageStrings;

  const onOptionSelect = (data: React.BaseSyntheticEvent) => {
    userContext.setOrderState(
      mapStateFromStringToEnum(data.target.textContent)
    );
  };

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton shape="circular" appearance="primary">
          {mapStateFromEnumToString(userContext.orderState)}
        </MenuButton>
      </MenuTrigger>

      <MenuPopover>
        <MenuList onClick={onOptionSelect}>
          <MenuItem>{localized.Pending}</MenuItem>
          <MenuItem>{localized.Provisioned}</MenuItem>
          <MenuItem>{localized.Declined}</MenuItem>
          <MenuItem>{localized.All}</MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
}

export default OrderFilterDropdown;
