import { Button } from "@fluentui/react-components";
import { Checkmark16Filled } from "@fluentui/react-icons";
import { useContext } from "react";
import { useStyles } from "../../../styles/styleClasses";
import { ApprovalUserContext } from "../../../interfaces/ApprovalUserContext";
import { UserContext } from "../../../contexts/UserContext";
import ApprovalService from "../../../services/ApprovalService";
import { ErrorHandlingService } from "../../../services/ErrorHandlingService";
import { DecideButtonProps } from "../../../interfaces/DecideButtonProps";

import {
  LanguageStrings,
  useLocalization,
} from "../../../contexts/LocalizationContext";

export default function ApproveButton({
  disabled,
  isMobile,
  orderId,
  comments,
  popOrder,
  overrideOnClick,
}: DecideButtonProps) {
  const styles = useStyles();
  const userContext = useContext<ApprovalUserContext>(UserContext);
  const localized = useLocalization() as LanguageStrings;

  const onClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    if (overrideOnClick) {
      overrideOnClick();
    } else {
      handleClickDefault();
    }
    e.stopPropagation();
  };

  function handleClickDefault() {
    ApprovalService.approveOrder(userContext, orderId, comments)
      .then(() => {
        popOrder();
      })
      .catch((error) => {
        ErrorHandlingService.handleApiError(error);
      });
  }

  return (
    <Button
      disabled={disabled}
      onClick={onClickEvent}
      className={isMobile ? styles.w100 : ""}
      appearance="primary"
      icon={<Checkmark16Filled />}
    >
      {localized.Approve}
    </Button>
  );
}
