import { Button } from "@fluentui/react-button";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { OrdersContext } from "../../contexts/OrdersContext";
import ApprovalService from "../../services/ApprovalService";
import { ErrorHandlingService } from "../../services/ErrorHandlingService";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";
function DecideMultipleBookings(props: {
  disabled: boolean;
  popOrder(): void;
  objectId: string;
  comments: string;
}) {
  const userContext = useContext(UserContext);
  const orderContext = useContext(OrdersContext);
  const localized = useLocalization() as LanguageStrings;

  useEffect(() => {
    if (orderContext.bookings[props.objectId]?.length === 0) {
      setButtonMessage(localized.DeclineOrders);
    } else {
      setButtonMessage(localized.ApproveSelected);
    }
  }, [orderContext?.bookings]);

  const [buttonMessage, setButtonMessage] = useState(localized.ApproveSelected);
  const getBookingsIdsToDecide = (
    key: string | undefined
  ): string[] | undefined => {
    if (key !== undefined) {
      return orderContext.bookings[key];
    }
    return [props.objectId];
  };

  function decideMultipleBookings() {
    ApprovalService.decideMultipleBookings(
      userContext,
      props.objectId,
      getBookingsIdsToDecide(props.objectId),
      props.comments
    )
      .then(() => {
        props.popOrder();
      })
      .catch((error) => {
        ErrorHandlingService.handleApiError(error);
      });
  }

  return (
    <Button
      appearance={"primary"}
      iconPosition="after"
      onClick={decideMultipleBookings}
      disabled={props.disabled}
    >
      <>{buttonMessage}</>
    </Button>
  );
}

export default DecideMultipleBookings;
