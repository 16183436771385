import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  mergeClasses,
} from "@fluentui/react-components";
import { useStyles } from "../../../../styles/styleClasses";
import { Order } from "../../../../interfaces/Order";
import PricingBlock from "../../PricingBlock";
import RequestDate from "./contentBodyFields/RequestDate";
import DecidedDate from "./contentBodyFields/DecidedDate";
import AdditionalDataField from "./contentBodyFields/AdditionalDataField";
import CostCenter from "./contentBodyFields/CostCenter";
import RecipientField from "./contentBodyFields/RecipientField";
import RequesterField from "./contentBodyFields/RequesterField";
import OrderMultipleBookingBody from "./OrderMultipleBookingBody";
import { useContext, useEffect, useState } from "react";
import { Booking } from "../../../../interfaces/Booking";
import { ApprovalUserContext } from "../../../../interfaces/ApprovalUserContext";
import { UserContext } from "../../../../contexts/UserContext";
import { TotalCosts } from "../../../../interfaces/TotalCosts";
import { BookingService } from "../../../../services/BookingService";
import { flattenBookingsTree } from "../../../../helpers/TreeHelper";
import { ErrorHandlingService } from "../../../../services/ErrorHandlingService";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../contexts/LocalizationContext";

function OrderModalContentBody(props: { order: Order }) {
  const styles = useStyles();

  //create a deep clone of the order. This way changes are not saved upon closing the modal.
  const cloneOrder = JSON.parse(JSON.stringify(props.order)) as Order;

  const [order, setOrder] = useState(cloneOrder);
  const userContext = useContext<ApprovalUserContext>(UserContext);
  const localized = useLocalization() as LanguageStrings;

  const [totalCosts, setTotalCosts] = useState(props.order.TotalCosts);
  useEffect(updateTotalPrice, [order]);
  function onBookingUpdate(newBookings: Booking[]) {
    setOrder((prevOrder) => {
      return { ...prevOrder, Bookings: newBookings };
    });
  }
  function updateTotalPrice() {
    const bookingIds = flattenBookingsTree(order.Booking)
      .filter((booking) => booking.IsCheckedForApproval)
      .map((booking) => booking.BookingId);

    if (bookingIds.length === 0) {
      setTotalCosts({} as TotalCosts);
      return;
    }
    try {
      BookingService.getCost(userContext, bookingIds).then(
        (costs: TotalCosts) => {
          setTotalCosts(costs);
        }
      );
    } catch (error) {
      ErrorHandlingService.handleException(error as Error);
      setTotalCosts({} as TotalCosts);
    }
  }

  return (
    <div className={mergeClasses(styles.pLg, styles.ptNone, styles.ptSm)}>
      <Accordion
        defaultOpenItems="1"
        className={mergeClasses(styles.borderNeutral1, styles.accordion)}
        collapsible
      >
        <AccordionItem value="1">
          <AccordionHeader
            className={mergeClasses(
              styles.borderNeutral1,
              styles.bgNeutral2,
              styles.accordion,
              styles.accordionHeader
            )}
          >
            {localized.RequestDetails}
          </AccordionHeader>
          <AccordionPanel className={mergeClasses(styles.mNone, styles.pMd)}>
            <div
              className={mergeClasses(styles.dGrid, styles.gridLayoutDialog)}
            >
              <RequestDate createdDate={order?.CreatedDate} />
              <RequesterField requester={order?.Requestor} />
              <RecipientField recipient={order?.Recipient} />
              <DecidedDate
                state={order?.State}
                decidedDate={order?.DecidedDate}
              />
              <AdditionalDataField data={order?.AdditionalData} />
              <CostCenter costCenter={order?.CostCenter} />
            </div>
            {(order.IsMultipleBooking || order.Booking[0].Children) && (
              <>
                {" "}
                <OrderMultipleBookingBody
                  order={order}
                  onBookingUpdate={onBookingUpdate}
                />
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <div
        className={mergeClasses(
          styles.bgAlpha1,
          styles.pMd,
          styles.borderNeutral1,
          styles.pricing
        )}
      >
        <PricingBlock order={order} totalCosts={totalCosts} />
      </div>
    </div>
  );
}

export default OrderModalContentBody;
