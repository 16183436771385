import { OrderState } from "../interfaces/OrderState";

export function getOrderStateColor(orderState: OrderState) {
    switch (orderState) {
      case 1040:
        return "warning";
      case 9999:
        return "brand";
      case 1043:
        return "danger";
      default:
        return "success";
    }
  }