import {
  ButtonProps,
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
  Tooltip,
} from "@fluentui/react-components";
import {
  TextSortAscending24Regular,
  TextSortDescending24Regular,
} from "@fluentui/react-icons";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function SortOptionsControl() {
  const userContext = useContext(UserContext);
  const sortOptions = userContext.getSortOptions();
  const localized = useLocalization() as LanguageStrings;

  const primaryActionProps: ButtonProps = {
    onClick: toggleSortDirection,
  };

  function toggleSortDirection() {
    if (sortOptions.sortDirection === "ascending") {
      userContext.saveSortOptions(sortOptions.sortColumn, "descending");
    } else if (sortOptions.sortDirection === "descending") {
      userContext.saveSortOptions(sortOptions.sortColumn, "ascending");
    }
  }

  const onOptionSelect = (data: React.BaseSyntheticEvent) => {
    userContext.saveSortOptions(
      data.target.textContent,
      sortOptions.sortDirection
    );
  };

  function getSortDirectionIcon() {
    if (sortOptions.sortDirection === "ascending") {
      return <TextSortAscending24Regular />;
    } else {
      return <TextSortDescending24Regular />;
    }
  }

  return (
    <Menu positioning="below-end">
      <MenuTrigger disableButtonEnhancement>
        {(triggerProps: MenuButtonProps) => (
          <Tooltip
            withArrow
            positioning="before"
            content={localized.SortBy}
            relationship="label"
          >
            <SplitButton
              iconPosition="before"
              icon={getSortDirectionIcon()}
              menuButton={triggerProps}
              primaryActionButton={primaryActionProps}
            >
              {sortOptions.sortColumn}
            </SplitButton>
          </Tooltip>
        )}
      </MenuTrigger>

      <MenuPopover>
        <MenuList onClick={onOptionSelect}>
          <MenuItem>{localized.ID}</MenuItem>
          <MenuItem>{localized.RequestDate}</MenuItem>
          <MenuItem>{localized.TotalPrice}</MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
}

export default SortOptionsControl;
