import { OrderState } from "../interfaces/OrderState";
import { OrdersManagement } from "../interfaces/OrdersManagement";

export function updateSelectedAllData(orderManagement: OrdersManagement) {
  orderManagement.setOrders({
    newOrdersList: orderManagement.orders.map((element) => {
      if (
        element.StateId === OrderState.Pending &&
        
        element.IsSelected === false
      ) {
        return { ...element, IsSelected: true };
      } else {
        return element;
      }
    }),
    itemWasSelected: false,
  });
}
