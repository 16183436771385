import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { OrdersManagement } from "../interfaces/OrdersManagement";
import ApprovalService from "../services/ApprovalService";
import { Order } from "../interfaces/Order";
import { ErrorHandlingService } from "../services/ErrorHandlingService";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";
import { UserContext } from "../contexts/UserContext";
import {
  defaultSkipOrdersNumber,
  orderStates,
  topOrdersCount,
} from "../services/Constants";
import { flattenBookingsTree } from "../helpers/TreeHelper";

export const useOrdersManagement = () => {
  const userContext = useContext<ApprovalUserContext>(UserContext);
  const numberOfSkipedOrders = useRef(defaultSkipOrdersNumber);
  const moreOrdersAvailable = useRef(true);
  const numberOfSelectedOrders = useRef(0);

  const [isSelectAllChecked, setSelectAllCheck] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [orders, setOrders] = useReducer(updateOrders, []);
  const [bookings, setBookings] =  useState<Record<string, string[]>>({})

  function updateOrders(
    state: Order[],
    action: { newOrdersList: Order[]; itemWasSelected: boolean }
  ) {
    state = action.newOrdersList;

    numberOfSelectedOrders.current = state.filter(
      (item) => item.IsSelected === true
    ).length;

    if (action.itemWasSelected) {
      checkForSelectedAll(
        state.filter((item) => item.IsSelected === true).length,
        state.filter(
          (item) =>
            item.State === orderStates.Pending
        ).length
      );
    }

    return state;
  }

  useEffect(() => {
    setIsloading(true);
    numberOfSkipedOrders.current = defaultSkipOrdersNumber;
    retrieveOrderData(true);
  }, [userContext.orderState, userContext.sortOptions]);

  function retrieveOrderData(isFirstRun: boolean) {
    if (!isFirstRun) {
      numberOfSkipedOrders.current =
        numberOfSkipedOrders.current + topOrdersCount;
    }
    ApprovalService.getApprovalData(
      userContext,
      userContext.orderState,
      topOrdersCount,
      numberOfSkipedOrders.current
    )
      .then((resp: Order[]) => {
        if (resp.length === 0 || resp.length < 10) {
          moreOrdersAvailable.current = false;
        } else {
          moreOrdersAvailable.current = true;
        }
        if (isFirstRun) {
          setOrders({ newOrdersList: resp, itemWasSelected: true });
        } else {
          setOrders({
            newOrdersList: [...orders, ...resp],
            itemWasSelected: true,
          });
        }
        setIsloading(false);
      })
      .catch((error) => {
        ErrorHandlingService.handleApiError(error);
      });
  }

  function popOrdersAfterDecide(decidedOrderObjectIds: string[]) {
    const newList = orders?.filter(
      (order) => !decidedOrderObjectIds.includes(order.ObjectId)
    );
    setOrders({ newOrdersList: newList, itemWasSelected: true });
  }

  function updateSelectedOrders(orderObjectId: string, isSelected: boolean) {
    setOrders({
      newOrdersList: orders.map((item) => {
        if (item.ObjectId === orderObjectId) {
          return { ...item, IsSelected: isSelected };
        } else {
          return item;
        }
      }),
      itemWasSelected: true,
    });
  }

  function addSelectedBookings (order: Order) {
    const updatedBookings = { ...bookings };

    if (!updatedBookings[order.ObjectId]) {
      updatedBookings[order.ObjectId] = [];
    }
    flattenBookingsTree(order.Booking).forEach(booking => updatedBookings[order.ObjectId].push(booking.BookingId))
    setBookings(updatedBookings);
  }
  
  function updateSelectedBookings (key: string, id: string) {
    setBookings(prevIds => {
      const updatedIds = { ...prevIds };
      if (updatedIds[key]) {
        if (updatedIds[key].includes(id)) {
          // Remove the ID if it exists in the array for the given key
          updatedIds[key] = updatedIds[key].filter(item => item !== id);
        } else {
          // Add the ID to the array for the given key
          updatedIds[key] = [...updatedIds[key], id];
        }
      } else {
        // If the key doesn't exist, create a new array with the ID
        updatedIds[key] = [id];
      }
      return updatedIds;
    });
}

  function checkForSelectedAll(
    selectedListLength: number,
    orderListLength: number
  ) {
    if (orderListLength !== null) {
      if (orderListLength === selectedListLength && orderListLength !== 0) {
        setSelectAllCheck(true);
      } else if (isSelectAllChecked === true) {
        setSelectAllCheck(false);
      }
    }
  }

  const managementContext: OrdersManagement = {
    orders: orders,
    bookings:bookings,
    setBookings:setBookings,
    setOrders: setOrders,
    retrieveOrderData: retrieveOrderData,
    moreOrdersAvailable: moreOrdersAvailable.current,
    updateSelectedOrders: updateSelectedOrders,
    updateSelectedBookings: updateSelectedBookings,
    addSelectedBookings:addSelectedBookings,
    isSelectAll: isSelectAllChecked,
    isLoading: isLoading,
    updateSelectAll: setSelectAllCheck,
    numberOfSelectedOrders: numberOfSelectedOrders.current,
    popOrdersAfterDecide: popOrdersAfterDecide,
  };

  return managementContext;
};
