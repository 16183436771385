import { Badge, mergeClasses } from "@fluentui/react-components";
import { useStyles } from "../../../../../styles/styleClasses";
import { Text } from "@fluentui/react-components";
import { getOrderStateColor } from "../../../../../helpers/GetOrderStateColor";
import { OrderState } from "../../../../../interfaces/OrderState";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../../contexts/LocalizationContext";

function OrderStateBadge(props: { stateId: OrderState }) {
  const styles = useStyles();
  const localized = useLocalization() as LanguageStrings;

  return (
    <Badge
      size="large"
      color={getOrderStateColor(props.stateId)}
      shape="rounded"
      appearance="outline"
      className={styles.mlSm}
    >
      <Text
        className={mergeClasses(styles.pSm, styles.noWrap)}
        weight="semibold"
        size={200}
      >
        {props.stateId === OrderState.Pending
          ? localized.Pending
          : props.stateId === OrderState.Approved
          ? localized.Approved
          : props.stateId === OrderState.Declined
          ? localized.Declined
          : props.stateId === OrderState.PendingOther
          ? localized.PendingOther
          : localized.Provisioned}
      </Text>
    </Badge>
  );
}

export default OrderStateBadge;
