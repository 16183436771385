import axios, { AxiosRequestConfig } from "axios";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";

const LicenseService = {
    isLicenseAvailable: async function(token: string, userContext: ApprovalUserContext) {
        const licenseCheckUrl = `${userContext.getDomainUrl()}/m42services/api/teamsapproval/license/validate`
        const requestConfig: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            validateStatus: () => true
        };
        const response = await axios.get(licenseCheckUrl, requestConfig);

        return response.status == 200
    } 

};

export default LicenseService;