
function AuthStart() {
    const queryParams = new URLSearchParams(window.location.search);        
    const ESMDomain = queryParams.get('ESMDomain');
    queryParams.delete("ESMDomain");
    const authorizeUrl = `${ESMDomain}/m42services/api/sts/authorize?${queryParams}`;
    window.location.assign(authorizeUrl);

    return (<div></div>)
}
export default AuthStart;