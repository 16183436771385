import { Divider, mergeClasses } from "@fluentui/react-components";
import { useStyles } from "../../styles/styleClasses";
import SettingsDialog from "../controls/SettingsDialog";
import SelectAllButton from "../controls/SelectAllButton";
import ClearSelectionButton from "../controls/ClearSelectionButton";
import ApproveSelectedButton from "../controls/decisionControls/ApproveSelectedButton";
import DeclineSelectedButton from "../controls/decisionControls/DeclineSelectedButton";
import OrderFilterButton from "../controls/OrderFilterButton";
import { OrderState } from "../../interfaces/OrderState";
import OrderFilterDropdown from "../controls/OrderFilterDropdown";
import DecideSelectedDropdown from "../controls/DecideSelectedDropdown";
import ChangeViewDialog from "../controls/ChangeViewDialog";
import SortOptionsControl from "../controls/SortOptionsControl";
import { ActionType } from "../../services/Constants";

function ApprovalsToolbar(props: {
   isMobile: boolean; 
   domainUrl: string;
   askForReason(actionType:ActionType):void;
   requireApprovalReason:boolean;
   }) {
  const styles = useStyles();

  return (
    <div className={mergeClasses(styles.toolbar, styles.w100)}>
      <div
        className={mergeClasses(
          styles.container,
          styles.mxAuto,
          styles.pXxl,
          styles.dFlex,
          styles.justifyBetween,
          styles.alignCenter,
          styles.hInherit
        )}
      >
        <div
          className={mergeClasses(
            styles.toolbarContent,
            styles.dFlex,
            styles.alignCenter,
            styles.gapSm
          )}
        >
          <SelectAllButton />
          {props.isMobile ? (
            <DecideSelectedDropdown />
          ) : (
            <>
              <ApproveSelectedButton comment="Approved from Approval Teams App." overrideOnClick={props.requireApprovalReason ? () => props.askForReason(ActionType.Approve) : undefined} />
              <DeclineSelectedButton overrideOnClick={() => props.askForReason(ActionType.Decline)}/>
              <ClearSelectionButton />
            </>
          )}
        </div>
        <div className={styles.justifyEnd}>
          <div
            className={mergeClasses(
              styles.toolbarContent,
              styles.dFlex,
              styles.alignCenter,
              styles.gapSm
            )}
          >
            {props.isMobile ? (
              <OrderFilterDropdown />
            ) : (
              <>
                <OrderFilterButton orderState={OrderState.Pending} />
                <OrderFilterButton orderState={OrderState.Provisioned} />
                <OrderFilterButton orderState={OrderState.Declined} />
                <OrderFilterButton orderState={OrderState.All} />
                <SortOptionsControl />
                <ChangeViewDialog />
              </>
            )}
            <SettingsDialog domainUrl={props.domainUrl} />
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
}

export default ApprovalsToolbar;
