import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";

export function getImageUrlSource(userContext: ApprovalUserContext, serviceImage:string) : string {
  const domainUrl = userContext.getDomainUrl();
  if(serviceImage === undefined)
    {
      serviceImage = (`${domainUrl}/wm/assets/images/no_image1.png`);
    }
    else if (serviceImage.startsWith("Services"))
    {
      serviceImage = (`${domainUrl}/SPS/Images/` + serviceImage);
    }
    else if (serviceImage.startsWith("/SPS/Images")){
      serviceImage = (`${domainUrl}` + serviceImage);
    }
    else if (serviceImage.startsWith("api/")){
      serviceImage = (`${domainUrl}/m42Services/` + serviceImage);
    }
    else {
      serviceImage = (`${domainUrl}/wm/assets/images/no_image1.png`);
    }
    return serviceImage;
  }