import { useContext, useEffect, useState } from "react";
import { useStyles } from "../../styles/styleClasses";
import {
  Button,
  Image,
  Input,
  Label,
  Link,
  Text,
  mergeClasses,
} from "@fluentui/react-components";
import { Open24Regular } from "@fluentui/react-icons";
import AuthenticationService from "../../services/AuthenticationService";
import LicenseService from "../../services/LicenseService";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { helpMatrix42ApprovalAppLink } from "../../services/Constants";
import { ApprovalUserContext } from "../../interfaces/ApprovalUserContext";
import { UserContext } from "../../contexts/UserContext";
import { extractValidDomainUrl } from "../../helpers/ExtractDomainUrl";
import { ErrorHandlingService } from "../../services/ErrorHandlingService";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

export default function Welcome(props: { setToken(token: string): void }) {
  const styles = useStyles();
  const [inputDomain, setinputDomain] = useState("");
  const [hasError, setHasErrors] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [isLicenseAvailable, setIsLicenseAvailable] = useState(true);
  const inputEventSubject = new Subject();

  const userContext: ApprovalUserContext = useContext(UserContext);
  const localized = useLocalization() as LanguageStrings;

  useEffect(() => {
    const ESMDomain = userContext.getDomainUrl();
    if (ESMDomain !== "") {
      setButtonDisabled(false);
    }
    setinputDomain(ESMDomain);
  }, []);

  inputEventSubject.pipe(debounceTime(800)).subscribe((event) => {
    const typedEvent = event as string;
    isSecuredUrl(typedEvent);
  });

  async function SignUp() {
    extractValidDomainUrl(inputDomain)
      .then((responseDomainUrl) => {
        userContext.setDomainUrl(responseDomainUrl);
        LoginWithPKCEProtocol();
      })
      .catch((error) => {
        setHasErrors(true);
        setButtonDisabled(true);
        ErrorHandlingService.handleApiError(error);
      });
  }

  async function LoginWithPKCEProtocol() {
    const token =
      await AuthenticationService.authenticateWithPKCEFlowAndGetToken(
        userContext
      );
    const hasLicense = await LicenseService.isLicenseAvailable(
      token,
      userContext
    );

    if (hasLicense) {
      props.setToken(token);
      localStorage.setItem("ESMToken", token);
    } else {
      setIsLicenseAvailable(hasLicense);
    }
  }

  function isSecuredUrl(value: string) {
    try {
      const parsedUrl = new URL(value);
      if (parsedUrl.protocol === "https:") {
        setHasErrors(false);
        setButtonDisabled(false);
      } else {
        setHasErrors(true);
        setButtonDisabled(true);
      }
    } catch (error) {
      setHasErrors(true);
      setButtonDisabled(true);
    }
  }

  const handleEsmDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setinputDomain(e.target.value);
    inputEventSubject.next(e.target.value);
    if (inputDomain === "") {
      setHasErrors(false);
    }
  };

  return (
    <div
      className={mergeClasses(
        styles.imagePosition,
        styles.gridLayout,
        styles.noTouch
      )}
    >
      <div className={styles.column}>
        <Image
          as="img"
          alt="Approvals icon"
          src="/static/img/welcome_approval.png"
        />
      </div>
      <div className={styles.column}>
        <Text as="h2" truncate weight="bold">
          {localized.Welcome}
        </Text>
      </div>
      <div className={styles.column}>
        <Text>{localized.GetStarted}</Text>
      </div>
      <div
        className={mergeClasses(
          styles.column,
          styles.flexDirection,
          styles.mtLg
        )}
      >
        <div className={styles.formGroup}>
          <Label className={styles.pSm} required htmlFor={"url-id"}>
            {localized.UrlOrSsp}
          </Label>
          <Input
            className={styles.inputWidth}
            required
            type="url"
            id={"url-id"}
            value={inputDomain}
            onChange={handleEsmDomainChange}
            placeholder={localized.AddEsmDomain}
          />
          {hasError === true && inputDomain !== "" && (
            <div
              className={mergeClasses(
                "validation-error",
                styles.textDanger,
                styles.ptXs
              )}
            >
              {localized.InvalidInput}
            </div>
          )}
          {isLicenseAvailable === false && (
            <div
              className={mergeClasses(
                "validation-error",
                styles.textDanger,
                styles.ptXs
              )}
            >
              {localized.NoValidLicense}
            </div>
          )}
        </div>
      </div>
      <div className={mergeClasses(styles.column, styles.mtSm, styles.mbXs)}>
        <Button
          className={styles.inputWidth}
          disabled={
            buttonDisabled === true || inputDomain === "" ? true : false
          }
          appearance="primary"
          onClick={SignUp}
        >
          {localized.SignIn}
        </Button>
      </div>
      <div className={styles.column}>
        <Link
          rel="noreferrer"
          target="_blank"
          href={helpMatrix42ApprovalAppLink}
        >
          <Text>
            {localized.LearnMore}
            <Open24Regular className={styles.helpIcon} />
          </Text>
        </Link>
      </div>
    </div>
  );
}
