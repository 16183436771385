import {
  Badge,
  Link,
  Text,
  Tooltip,
  mergeClasses,
} from "@fluentui/react-components";
import { getOrderURL } from "../../../../../services/Constants";
import { useStyles } from "../../../../../styles/styleClasses";
import { Order } from "../../../../../interfaces/Order";
import { useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { Open16Regular } from "@fluentui/react-icons";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../../contexts/LocalizationContext";

function OrderIdentification(props: { order: Order }) {
  const styles = useStyles();
  const userContext = useContext(UserContext);
  const localized = useLocalization() as LanguageStrings;

  return (
    <div className={mergeClasses(styles.plMd, styles.overflowHidden)}>
      <Text
        as="h4"
        truncate
        weight="semibold"
        className={mergeClasses(
          styles.dFlex,
          styles.pbXs,
          styles.mNone,
          styles.textSemiBold,
          styles.textSizeLg,
          styles.textNeutralForeground1,
          styles.orderIdentification
        )}
      >
        {props.order?.Name}
      </Text>
      <Text block className={styles.textNeutralForeground3}>
        {localized.RequestId}:{" "}
        <Tooltip
          withArrow
          positioning="after"
          content={localized.ViewRequestSelfServicePortal}
          relationship="label"
        >
          <Link
            rel="noreferrer"
            target="_blank"
            href={getOrderURL(props.order.ObjectId, userContext.getDomainUrl())}
          >
            <Badge
              aria-label="ItemNumberLink"
              className={styles.badgeLinkIcon}
              icon={<Open16Regular />}
              appearance="ghost"
              iconPosition="after"
            >
              {props.order?.IsMultipleBooking === true
                ? `${props.order?.ItemNumber} - ` + localized.MultipleService
                : `${props.order?.ItemNumber}`}
            </Badge>
          </Link>
        </Tooltip>
      </Text>
    </div>
  );
}

export default OrderIdentification;
