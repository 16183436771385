import { Text } from "@fluentui/react-components";
import { useStyles } from "../../../../../styles/styleClasses";
import { mergeClasses } from "@fluentui/react-components";
import { orderStates } from "../../../../../services/Constants";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../../contexts/LocalizationContext";

function DecidedDate(props: { state: string; decidedDate: string }) {
  const styles = useStyles();
  const localized = useLocalization() as LanguageStrings;

  return (
    <div className={styles.overflowHidden}>
      <div className={styles.pbXs}>
        <Text truncate className={styles.textNeutralForeground3}>
          {localized.DecidedDate}
        </Text>
      </div>
      <div className={styles.dFlex}>
        <Text
          block
          truncate
          weight="semibold"
          className={mergeClasses(
            styles.textNeutralForeground1,
            styles.textOverflow,
            styles.overflowHidden,
            styles.dFlex
          )}
        >
          {!props.state.includes(orderStates.Pending)
            ? `${props.decidedDate}`
            : `-`}
        </Text>
      </div>
    </div>
  );
}

export default DecidedDate;
