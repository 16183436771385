
/// Placeholder for application insights implementation. Use the same logger structure afterwards too.
export const LoggerService = {
    logInfo: function (message: string) {
        console.log(message);
    },

    logError: function (error: string) {
        console.log(error);
    },
}