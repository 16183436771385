import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  Input,
  Tooltip,
  mergeClasses,
  Text,
  DialogTitle,
  Divider,
  Link,
} from "@fluentui/react-components";
import { useStyles } from "../../styles/styleClasses";
import {
  Settings24Regular,
  Dismiss16Filled,
  Open24Regular,
} from "@fluentui/react-icons";
import { helpMatrix42ApprovalAppLink } from "../../services/Constants";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function SettingsDialog(props: { domainUrl: string }) {
  const localized = useLocalization() as LanguageStrings;

  const styles = useStyles();
  const [open, setOpen] = useState(false);
  function SignOut() {
    localStorage.removeItem("ESMToken");
    window.location.reload();
  }
  return (
    <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <Tooltip
          withArrow
          positioning="before"
          content={localized.Settings}
          relationship="label"
        >
          <Button icon={<Settings24Regular />} />
        </Tooltip>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            <DialogTrigger disableButtonEnhancement>
              <Button
                className={mergeClasses(
                  styles.closeButtonTrigger,
                  styles.btnAvailableInfo
                )}
                icon={<Dismiss16Filled />}
                appearance="transparent"
                iconPosition="after"
              />
            </DialogTrigger>

            <div>
              <Text
                size={500}
                as="h4"
                weight="semibold"
                className={mergeClasses(
                  styles.textOverflow,
                  styles.overflowHidden,
                  styles.textNeutralForeground1,
                  styles.dFlex,
                  styles.mNone
                )}
              >
                {localized.Settings}
              </Text>
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              <Text>{localized.SettingsDescription}</Text>
            </div>
            <div className={styles.mtLg}>
              <Text as="h2" truncate weight="bold">
                {localized.ConnectedDomain}
              </Text>
            </div>
            <div>
              <Text>{localized.SelfServicePortal}</Text>
            </div>
            <div className={styles.formGroup}>
              <Input
                className={mergeClasses(styles.w100, styles.mtSm)}
                required
                type="url"
                id={"url-id"}
                disabled={true}
                value={props.domainUrl}
              />
            </div>
            <div className={styles.mtLg}>
              <Divider className={mergeClasses(styles.mtLg, styles.mblg)} />
              <Text as="h2" truncate weight="bold">
                {localized.Help}
              </Text>
            </div>
            <div className={styles.mtSm}>
              <Text>{localized.ClickOnLink}</Text>
            </div>
            <div>
              <Link
                rel="noreferrer"
                target="_blank"
                href={helpMatrix42ApprovalAppLink}
              >
                <Text>
                  {localized.LearnMore}
                  <Open24Regular className={styles.helpIcon} />
                </Text>
              </Link>
              <Divider className={styles.mtLg} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={SignOut}>
              {localized.Logout}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default SettingsDialog;
