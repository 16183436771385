import { SortingSettings } from "../interfaces/SortingSettings";
import { sortOptionsStrings } from "../services/Constants";

export function createSortQuery(sortOptions: SortingSettings) {
  let sortQuery = "";
  switch (sortOptions.sortColumn) {
    case sortOptionsStrings.RequestDate:
      sortQuery = "CreatedDate";
      break;
    case sortOptionsStrings.TotalPrice:
      sortQuery = "TotalCosts/Total/Value";
      break;
    case sortOptionsStrings.OrderId:
    default:
      sortQuery = "ItemNumber";
      break;
  }

  if (sortOptions.sortDirection === "ascending") {
    sortQuery += " asc";
  } else {
    sortQuery += " desc";
  }

  return sortQuery;
}
