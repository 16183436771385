import axios from "axios";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";
import { TokenService } from "./TokenService";

function getAxiosInstance(userContext: ApprovalUserContext) {
  const apiOrdersUrl = `${userContext.getDomainUrl()}/m42services/api/servicebookings`;
  return axios.create({
    baseURL: apiOrdersUrl,
    headers: {
      Authorization: TokenService.getAuthorizationHeader(),
      "content-type": "application/json",
      accept: "application/json",
    },
  });
}

export const BookingService = {
  getCost: async function (userContext: ApprovalUserContext, bookingIds: string[]) {
    try {
      const response = await getAxiosInstance(userContext).post("/Costs", {
        CostCenterDecider: false,
        CurrencyCode: null,
        WhereExpression: `ID IN ('${bookingIds.join("','")}')`,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching costs:", error);
      throw error; // Propagate the error for handling in the component/service calling this method
    }
  },
};