import { useEffect, useState } from "react";
import { useStyles } from "../../styles/styleClasses";
import {
  Divider,
  Text,
  Badge,
  mergeClasses,
  Persona,
  Card,
  CardFooter,
} from "@fluentui/react-components";
import { Order } from "../../interfaces/Order";
import PricingBlock from "./PricingBlock";
import AdditionalInfo from "../controls/AdditionalInfo";
import { getOrderStateColor } from "../../helpers/GetOrderStateColor";
import { LoadMoreOrders } from "../controls/LoadMoreOrders";
import OrderCheckbox from "../controls/OrderCheckbox";
import { orderStates } from "../../services/Constants";
import ApproveButton from "../controls/decisionControls/ApproveButton";
import DeclineButton from "../controls/decisionControls/DeclineButton";
import { OrderState } from "../../interfaces/OrderState";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

export function ApprovalCards(props: {
  orderData: Order[];
  refreshOrder(order: Order): void;
  setDialogData(order: Order): void;
  requireApprovalReason: boolean;
}) {
  const localized = useLocalization() as LanguageStrings;

  const classes = useStyles();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 768 ? false : true
  );
  const [isCardHover, setIsCardHover] = useState<number | null>(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  const handleMouseOver = (id: number) => {
    setIsCardHover(id);
  };

  const handleMouseOut = () => {
    setIsCardHover(null);
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return (
    <div>
      <div className={mergeClasses(classes.dGrid, classes.gridLayout)}>
        {props.orderData.map((item, index: number) => (
          <Card
            id={index.toString()}
            onMouseEnter={() => handleMouseOver(index)}
            onMouseLeave={() => handleMouseOut()}
            key={index}
            appearance="filled"
            onClick={() => props.setDialogData(item)}
            className={mergeClasses(
              classes.card,
              classes.borderRadiusMd,
              classes.pNone,
              classes.gapNone,
              classes.borderNeutral1,
              item.IsSelected && classes.cardSelected
            )}
          >
            <div
              className={mergeClasses(
                classes.pMd,
                classes.alignCenter,
                classes.dFlex,
                classes.justifyBetween,
                classes.responsiveTitle
              )}
            >
              <div
                className={mergeClasses(
                  classes.alignCenter,
                  classes.dFlex,
                  classes.overflowHidden,
                  classes.w100
                )}
              >
                <div
                  className={mergeClasses(
                    classes.image,
                    classes.dFlex,
                    classes.alignCenter
                  )}
                >
                  <OrderCheckbox item={item} />
                </div>
                <div className={mergeClasses(classes.overflowHidden)}>
                  <Text block className={classes.textNeutralForeground3}>
                    {item.IsMultipleBooking === true
                      ? `${item.ItemNumber} - ` + localized.MultipleService
                      : `${item.ItemNumber}`}
                  </Text>
                  <Text
                    truncate
                    weight="semibold"
                    className={mergeClasses(classes.orderIdentification)}
                  >
                    {item.Name}
                  </Text>
                </div>
              </div>
              <div className={classes.responsiveHeading}>
                <Badge
                  size="large"
                  color={getOrderStateColor(item.StateId)}
                  shape="rounded"
                  appearance="outline"
                  className={classes.mlSm}
                >
                  <Text
                    className={mergeClasses(classes.pSm, classes.noWrap)}
                    weight="semibold"
                    size={200}
                  >
                    {item.StateId === OrderState.Pending
                      ? localized.Pending
                      : item.StateId === OrderState.Approved
                      ? localized.Approved
                      : item.StateId === OrderState.Declined
                      ? localized.Declined
                      : item.StateId === OrderState.PendingOther
                      ? localized.PendingOther
                      : localized.Provisioned}
                  </Text>
                </Badge>
              </div>
            </div>
            {isMobile && item.State === orderStates.Pending && (
              <>
                <Divider />
                <CardFooter
                  className={mergeClasses(
                    classes.pMd,
                    classes.dFlex,
                    classes.alignCenter
                  )}
                >
                  <>
                    <ApproveButton
                      disabled={false}
                      isMobile={isMobile}
                      orderId={item.ObjectId}
                      comments={"Approved from approval app."}
                      popOrder={() => {
                        props.refreshOrder(item);
                      }}
                      overrideOnClick={
                        props.requireApprovalReason
                          ? () => props.setDialogData(item)
                          : undefined
                      }
                    />
                    <DeclineButton
                      disabled={false}
                      isMobile={isMobile}
                      orderId={item.ObjectId}
                      comments={"Declined from approval app."}
                      popOrder={() => {
                        props.refreshOrder(item);
                      }}
                      overrideOnClick={() => props.setDialogData(item)}
                    />
                  </>
                </CardFooter>
              </>
            )}
            <Divider />
            <div className={classes.bgNeutral2}>
              <div className={classes.pLg}>
                <div
                  className={mergeClasses(
                    classes.dGrid,
                    classes.gridLayoutInner
                  )}
                >
                  <div className={classes.overflowHidden}>
                    <div className={classes.pbXs}>
                      <Text truncate className={classes.textNeutralForeground3}>
                        {localized.Recipient}
                      </Text>
                    </div>
                    <Persona
                      className={mergeClasses(classes.persona, classes.dFlex)}
                      size="extra-small"
                      name={item.Recipient}
                      textAlignment="center"
                    />
                  </div>
                  <div className={classes.additionalInfo}>
                    <div className={classes.pbXs}>
                      <Text truncate className={classes.textNeutralForeground3}>
                        {localized.AdditionalInformation}
                      </Text>
                    </div>
                    <div className={classes.dFlex}>
                      {item.AdditionalData !== undefined ? (
                        <AdditionalInfo formData={item.AdditionalData} />
                      ) : (
                        <Text
                          block
                          truncate
                          weight="semibold"
                          className={mergeClasses(
                            classes.textNeutralForeground1,
                            classes.textOverflow,
                            classes.overflowHidden,
                            classes.dFlex
                          )}
                        >
                          -
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div className={classes.pLg}>
                <PricingBlock order={item} totalCosts={item.TotalCosts} />
              </div>
            </div>
            <Divider />

            <CardFooter
              action={
                <>
                  {isCardHover === index &&
                  isMobile === false &&
                  item.StateId === OrderState.Pending ? (
                    <div
                      className={mergeClasses(
                        classes.footerActions,
                        classes.dFlex
                      )}
                    >
                      <div className={classes.dFlex}>
                        <div className={classes.mrSm}>
                          {" "}
                          <ApproveButton
                            disabled={false}
                            isMobile={false}
                            orderId={item.ObjectId}
                            comments={"Approved from approval app."}
                            popOrder={() => {
                              props.refreshOrder(item);
                            }}
                            overrideOnClick={
                              props.requireApprovalReason
                                ? () => props.setDialogData(item)
                                : undefined
                            }
                          />
                        </div>
                        <div>
                          <DeclineButton
                            disabled={false}
                            isMobile={false}
                            orderId={item.ObjectId}
                            comments={"Declined from approval app."}
                            popOrder={() => {
                              props.refreshOrder(item);
                            }}
                            overrideOnClick={() => props.setDialogData(item)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    !item.State.includes(orderStates.Pending) && (
                      <div className={classes.overflowHidden}>
                        <Text
                          block
                          align="end"
                          truncate
                          className={mergeClasses(
                            classes.textNeutralForeground3,
                            classes.textOverflow,
                            classes.overflowHidden
                          )}
                        >
                          {localized.DecidedDate}
                        </Text>
                        <Text
                          block
                          align="end"
                          truncate
                          weight="semibold"
                          className={mergeClasses(
                            classes.textNeutralForeground1,
                            classes.textOverflow,
                            classes.overflowHidden
                          )}
                        >
                          {item.DecidedDate}
                        </Text>
                      </div>
                    )
                  )}
                </>
              }
              className={mergeClasses(classes.pMd, classes.alignCenter)}
            >
              <div className={classes.overflowHidden}>
                <Text
                  block
                  truncate
                  className={mergeClasses(
                    classes.textNeutralForeground3,
                    classes.textOverflow,
                    classes.overflowHidden
                  )}
                >
                  {localized.RequestDate}
                </Text>
                <Text
                  block
                  truncate
                  weight="semibold"
                  className={mergeClasses(
                    classes.textNeutralForeground1,
                    classes.textOverflow,
                    classes.overflowHidden
                  )}
                >
                  {item.CreatedDate}
                </Text>
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
      <LoadMoreOrders />
    </div>
  );
}
