import {
  Menu,
  MenuButton,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
} from "@fluentui/react-components";
import ApproveSelectedButton from "./decisionControls/ApproveSelectedButton";
import DeclineSelectedButton from "./decisionControls/DeclineSelectedButton";
import ClearSelectionButton from "./ClearSelectionButton";
import { useContext } from "react";
import { OrdersContext } from "../../contexts/OrdersContext";
import { useStyles } from "../../styles/styleClasses";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function DecideSelectedDropdown() {
  const styles = useStyles();
  const orderManagementContext = useContext(OrdersContext);
  const localized = useLocalization() as LanguageStrings;

  if (orderManagementContext.numberOfSelectedOrders !== 0)
    return (
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton shape="rounded">{localized.Decide}</MenuButton>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <div className={mergeClasses(styles.decideMenu)}>
              <ApproveSelectedButton />
              <DeclineSelectedButton />
              <ClearSelectionButton />
            </div>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  else return <></>;
}

export default DecideSelectedDropdown;
