import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import enStrings from "./../locales/en-US.json";
import deStrings from "./../locales/de-DE.json";
import itStrings from "./../locales/it-IT.json";
import frStrings from "./../locales/fr-FR.json";
import { ErrorHandlingService } from "../services/ErrorHandlingService";

export type LanguageStrings = typeof enStrings; // Define the type for the language strings

export const LocalizationContext = createContext<LanguageStrings | undefined>(
  undefined
);

export const useLocalization = () => {
  const context = useContext(LocalizationContext);
  if (!context) {
    ErrorHandlingService.handleException(
      new Error(
        "useLocalization must be used within a LocalizationProvider"
      ) as Error
    );
  }
  return context;
};
interface LocalizationProviderProps {
  children: ReactNode;
}
export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({
  children,
}) => {
  const [languageStrings, setLanguageStrings] =
    useState<LanguageStrings>(enStrings);
  useEffect(() => {
    microsoftTeams.app
      .getContext()
      .then((context) => {
        if (context && context.app.locale) {
          switch (context.app.locale) {
            case "en-us":
              setLanguageStrings(enStrings);
              break;
            case "de-de":
              setLanguageStrings(deStrings);
              break;
            case "it-it":
              setLanguageStrings(itStrings);
              break;
            case "fr-fr":
              setLanguageStrings(frStrings);
              break;
            default:
              setLanguageStrings(enStrings);
          }
        }
      })
      .catch((error) => {
        ErrorHandlingService.handleException(error);
      });
  }, []);

  return (
    <LocalizationContext.Provider value={languageStrings}>
      {children}
    </LocalizationContext.Provider>
  );
};
