import { AxiosError } from 'axios';
import { LoggerService } from './LoggerService';
import { TokenService } from './TokenService';

export const ErrorHandlingService = {

    handleException: function (error: Error) {
        LoggerService.logError(error.message);
    },

    handleApiError: function (error: AxiosError) {
        LoggerService.logError(error.message);
        if (error as AxiosError && error.response?.status === 406) {
            TokenService.refreshToken();
        }
    }
}
