import axios from "axios";
import { OrderState } from "../interfaces/OrderState";
import { ApprovalUserContext } from "../interfaces/ApprovalUserContext";
import { mapOrderData } from "../helpers/MapOrderData";
import { createSortQuery } from "../helpers/CreateSortQuery";
import { TokenService } from "./TokenService";

export const ApprovalService = {
  getAxiosInstance: function (userContext: ApprovalUserContext) {
    const apiOrdersUrl = `${userContext.getDomainUrl()}/m42services/api/orders`;
    return axios.create({
      baseURL: apiOrdersUrl,
      headers: {
        Authorization: TokenService.getAuthorizationHeader(),
        "content-type": "application/json",
        accept: "application/json",
      },
    });
  },
  getExternalImageUrl: async function (externalImageUrl: string) {
    return axios({
      method: "GET",
      url: externalImageUrl,
      responseType: "blob",
      headers: {
        Authorization: TokenService.getAuthorizationHeader(),
      },
    }).then((response) => {
      return response.data;
    });
  },

  getApprovalData: async function (
    userContext: ApprovalUserContext,
    state: OrderState,
    top: number,
    skip: number
  ) {
    const stateFilter =
      state === OrderState.All
        ? `(StateId eq 1040 or StateId eq 1042 or StateId eq 1043 or StateId eq 1045)`
        : `(StateId eq ${state})`;
    const deciderFilter =
      state === OrderState.Pending ? `(DecideAllowed eq true)` : null;

    const filter =
      deciderFilter !== null
        ? `$filter=(${stateFilter} and ${deciderFilter})`
        : `$filter=${stateFilter}`;

    const sortQuery = createSortQuery(userContext.getSortOptions());

    const ordersQueryParams = `?decider=true&costCenterDecider=false&catalogRequest=false&minimalResultSet=false&$orderby=${sortQuery}&$top=${top}&$skip=${skip}&${filter}`;
    const response = await this.getAxiosInstance(userContext).get(
      ordersQueryParams
    );

    return mapOrderData(userContext, response.data);
  },


  approveOrder: async function (
    userContext: ApprovalUserContext,
    orderID: string,
    comments: string
  ) {
    const approveRoute = `/${orderID}/approve`;
    const response = await this.getAxiosInstance(userContext).post(
      approveRoute,
      {
        Id: orderID,
        Comments: comments,
        AttachmentFolder: "none",
        CustomForms: [],
      }
    );
    return response.data;
  },

  decideMultipleBookings: async function (
    userContext: ApprovalUserContext,
    orderIDs: string | undefined,
    bookingIds:string[] | undefined,
    comments: string
  ) {
    let isApproved = true;
    if(bookingIds?.length === 0 || bookingIds === undefined){
      isApproved = false;
    }
    const approveRoute = `/decideOrdersOrBookings?actionId=ac868097-9f4e-c975-030f-08d4af08b5ed&isApproved=${isApproved}`;
    const response = await this.getAxiosInstance(userContext).post(
      approveRoute,
      {
        Ids: [orderIDs],
        BookingIds:bookingIds,
        Comments: comments,
        AttachmentFolder: "none",
        ApprovalTaskObjectId:null,
        DecideChildBookings: false,
        CustomForms: [],
      }
    );
    return response.data;
  },

  approveMultipleOrders: async function (
    userContext: ApprovalUserContext,
    orderIDs: string[],
    comments: string
  ) {
    const approveRoute = `/approve`;
    const response = await this.getAxiosInstance(userContext).post(
      approveRoute,
      {
        Ids: orderIDs,
        Comments: comments,
        AttachmentFolder: "none",
        CustomForms: [],
      }
    );
    return response.data;
  },

  declineOrder: async function (
    userContext: ApprovalUserContext,
    orderID: string,
    comments: string
  ) {
    const declineRoute = `/${orderID}/decline?comments=${comments}`;
    const response = await this.getAxiosInstance(userContext).post(
      declineRoute
    );
    return response.data;
  },

  declineMultipleOrders: async function (
    userContext: ApprovalUserContext,
    orderIDs: string[],
    comments: string
  ) {
    const declineRoute = `/decline`;
    const response = await this.getAxiosInstance(userContext).post(
      declineRoute,
      {
        Ids: orderIDs,
        Comments: comments,
        AttachmentFolder: "none",
      }
    );
    return response.data;
  },
};

export default ApprovalService;
