import { Badge, Link, Tooltip } from "@fluentui/react-components";
import { Open16Regular } from "@fluentui/react-icons";
import { useStyles } from "../../styles/styleClasses";
import { AdditionalData } from "../../interfaces/AdditionalData";
import { getAdditionalInfoURL } from "../../services/Constants";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

function AdditionalInfo(props: { formData: AdditionalData }) {
  const styles = useStyles();
  const userContext = useContext(UserContext);
  const localized = useLocalization() as LanguageStrings;

  const onClickEvent = (e: React.MouseEvent<HTMLElement>) =>
    e.stopPropagation();

  return (
    <Link
      rel="noreferrer"
      target="_blank"
      onClick={onClickEvent}
      href={getAdditionalInfoURL(
        props.formData.FormObjectId,
        props.formData.FormEnitityName,
        userContext.getDomainUrl()
      )}
    >
      <Tooltip
        withArrow
        positioning="above"
        content={localized.ViewAdditionalInfoInSelfServicePortal}
        relationship="label"
      >
        <Badge
          aria-label="ViewDetails"
          className={styles.badgeLinkIcon}
          icon={<Open16Regular />}
          appearance="ghost"
          iconPosition="after"
        >
          {localized.ViewDetails}
        </Badge>
      </Tooltip>
    </Link>
  );
}

export default AdditionalInfo;
