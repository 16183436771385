import {
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogTrigger,
  Button,
  mergeClasses,
  Divider,
} from "@fluentui/react-components";
import { Dismiss16Filled } from "@fluentui/react-icons";
import { useStyles } from "../../styles/styleClasses";
import ApproveSelectedButton from "../controls/decisionControls/ApproveSelectedButton";
import OrderModalComments from "./orderModal/childComponents/OrderModalComments";
import { useState } from "react";
import DeclineSelectedButton from "../controls/decisionControls/DeclineSelectedButton";
import { OrderModalHeaderField } from "./orderModal/childComponents/OrderModalHeaderField";
import {
  LanguageStrings,
  useLocalization,
} from "../../contexts/LocalizationContext";

export function AskForReasonModal(props: {
  onClose(): void;
  isApprovalAction: boolean;
}) {
  const styles = useStyles();
  const [comment, setComment] = useState("");
  const localized = useLocalization() as LanguageStrings;

  return (
    <div>
      <DialogSurface
        className={mergeClasses(styles.dialog, styles.dFlex, styles.flexColumn)}
      >
        <DialogTitle>
          <DialogTrigger disableButtonEnhancement>
            <Button
              className={mergeClasses(
                styles.closeButtonTrigger,
                styles.btnAvailableInfo
              )}
              icon={<Dismiss16Filled />}
              appearance="transparent"
              iconPosition="after"
            />
          </DialogTrigger>
          <OrderModalHeaderField title={localized.ApprovalReason} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <OrderModalComments
            isMultipleBooking={true}
            inputComment={comment}
            required={true}
            setInputComment={setComment}
          />
        </DialogContent>
        <DialogActions>
          <div className={mergeClasses(styles.decideSelectedModalButtonParent)}>
            {props.isApprovalAction ? (
              <ApproveSelectedButton
                disabled={comment.length === 0}
                onClickCallback={props.onClose}
                comment={comment}
              />
            ) : (
              <DeclineSelectedButton
                disabled={comment.length === 0}
                onClickCallback={props.onClose}
                comment={comment}
              />
            )}
          </div>
        </DialogActions>
      </DialogSurface>
    </div>
  );
}
