import {
  Label,
  mergeClasses,
  Text,
  Textarea,
} from "@fluentui/react-components";
import { useStyles } from "../../../../styles/styleClasses";
import {
  LanguageStrings,
  useLocalization,
} from "../../../../contexts/LocalizationContext";

function OrderModalComments(props: {
  isMultipleBooking: boolean;
  inputComment: string;
  setInputComment(comment: string): void;
  required: boolean;
}) {
  const localized = useLocalization() as LanguageStrings;

  const styles = useStyles();
  return (
    <div
      className={mergeClasses(
        styles.pLg,
        styles.ptNone,
        styles.dFlex,
        styles.flexColumn
      )}
    >
      <div className={styles.pbSm}>
        <Label required={props.required} htmlFor={"comments-textarea"}>
          <Text size={400} weight="semibold">
            {localized.Comments}
          </Text>
        </Label>
      </div>
      <div>
        <Textarea
          aria-label="CommentsInput"
          className={styles.w100}
          autoFocus
          required={props.required}
          size="large"
          resize="vertical"
          id={"comments-textarea"}
          value={props.inputComment}
          onChange={(e) => props.setInputComment(e.target.value)}
          placeholder={localized.AddAnyCommentsYouHave}
        />
        {props.required && props.inputComment.length === 0 && (
          <div
            className={mergeClasses(
              "validation-error",
              styles.textDanger,
              styles.ptXs
            )}
          >
            {localized.CommentIsRequired}
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderModalComments;
